import React from 'react'
import { MdOutlineFileDownload } from 'react-icons/md'

const PdfDownload = ({handleDownloadPDF, openModal, dataEvent}) => {
  return (
    <div className="animate_fade_up z-50 mt-[7.5rem] -ml-14 absolute transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col text-left  rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
    <button
        className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
        onClick={() => {
            openModal({
                id: dataEvent?.id,
                categories: ['General Discussions'],
                sort: 'desc',
            })
        }}
    >
        General Discussions PDF{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>
    <button
        className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
        onClick={() => {
            openModal({
                id: dataEvent?.id,
                categories: ['Chatroom KIQs'],
                sort: 'desc',
            })
        }}
    >
        Chatroom PDF{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>

    <button
        className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
        onClick={() => {
            openModal({
                id: dataEvent?.id,
                categories: ['My KIQs'],
                sort: 'desc',
            })
        }}
    >
        My KIQs PDF{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>
    <button
        className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
        onClick={() => {
            openModal({
                id: dataEvent?.id,
                categories: ['SnapIntel'],
                sort: 'desc',
            })
        }}
    >
        SnapIntel PDF{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>
    <button
        className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
        onClick={() =>
            openModal({
                id: dataEvent?.id,
                categories:   [
                    'General Discussions',
                    'Chatroom KIQs',
                    'My KIQs',
                    'SnapIntel',
                ],
                sort: 'desc',
            })
        }
    >
        Event PDF{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>
</div>  )
}

export default PdfDownload