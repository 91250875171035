import React, { useMemo, useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import LoadingImage from '../../../assets/NexusBrain.png'
import DataTable from 'react-data-table-component'
import { IoLogoWechat } from 'react-icons/io5'
import { useAtomValue } from 'jotai'
import { authAtom } from 'store/authAtom'
import { GeneralSnapIntel } from 'components/ChatSelection/GeneralSnapIntel'
import { LoadingComponent } from 'components/LoadingComponent'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { searchValueAtom } from 'pages/MainDashboard'
import Highlighter from 'react-highlight-words'

export const Companies = ({ searchCompanies, setCompaniesContainer, setTabs }) => {
    const [display, setDisplay] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    const { user } = useAtomValue(authAtom)
    const searchValue = useAtomValue(searchValueAtom)

    const AdminColum = [
        {
            name: 'Company Name',
            selector: (row) => row?.firstname,
            cell: (row) => (
                <div
                    className={`h-full w-full flex items-center`}
                    onClick={() => handleRowClicked(row)}
                >
                    <Highlighter
                        className={
                            row.firstname === selectedItem ? 'underline font-bold text-main' : ''
                        }
                        searchWords={[searchValue]}
                        autoEscape={true}
                        textToHighlight={row.firstname || ''}
                    />
                </div>
            ),
        },
    ]

    const CompanyColum = [
        {
            name: 'Questions',
            selector: (row) => row?.question,
            cell: (row) => (
                <div
                    className="h-full w-full flex items-center"
                    onClick={() => handleRowClicked(row)}
                >
                    <Highlighter
                        searchWords={[searchValue]}
                        autoEscape={true}
                        textToHighlight={row.question || ''}
                    />
                </div>
            ),
        },
    ]

    const handleRowClicked = (row) => {
        setCompaniesContainer(row)
        setSelectedItem(row.firstname)
        setTabs(3)
    }
    return (
        <div className="w-full">
            <button
                onClick={() => {
                    setDisplay(!display)
                    setCompaniesContainer(searchCompanies?.[0])
                    setSelectedItem(searchCompanies?.[0]?.firstname)
                    setTabs(3)
                }}
                disabled={searchCompanies?.length == 0 && true}
                className="transition-all duration-200 delay-100 cursor-pointer flex justify-between p-4 w-full text-white bg-[#6cb46c] rounded-md shadow-md animate_fade_up relative hover:bg-opacity-100 z-10"
            >
                <div className="flex items-center">
                    <span className="text-xl">
                        <BiChevronDown />
                    </span>
                    {user?.role === 'company' ? (
                        <p>General SnapIntel Questions</p>
                    ) : (
                        <p>Companies General SnapIntel</p>
                    )}
                </div>
                {searchCompanies ? (
                    <p className="border-white border px-2 rounded-full font-bold shadow-md">
                        <React.Fragment>{searchCompanies?.length ?? 0} </React.Fragment>
                    </p>
                ) : (
                    <img
                        src={LoadingImage}
                        className="w-[25px] animate_slow_spin brightness-200"
                        alt=""
                    />
                )}
            </button>
            {display && (
                <React.Fragment>
                    <div
                        className={twMerge(
                            'transition-all duration-200 cursor-pointer w-full bg-white shadow-md pt-8 pb-2 px-4 -mt-5 rounded-md animate_fade_up',
                            user?.role === 'company' && 'Card-Styling'
                        )}
                    >
                        <DataTable
                            progressComponent={<LoadingComponent title={'Companies'} />}
                            progressPending={!searchCompanies}
                            data={searchCompanies}
                            columns={user?.role === 'admin' ? AdminColum : CompanyColum}
                            fixedHeader
                            fixedHeaderScrollHeight="300px"
                            pagination
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export const CompaniesDisplay = ({ dataContainer, defaultQuestions }) => {
    const { allResponses: responses, questions } = useMemo(() => {
        const allResponses = dataContainer?.company?.flatMap((company) => {
            const filteredResponse = company?.responses?.filter((res) => res?.length !== 0)
            return filteredResponse || []
        })
        const questions = defaultQuestions
            .slice(0, -1)
            .filter((q) => allResponses.some((response) => response.question_id === q.id))

        // setSelectedQuestion(null)

        return { allResponses, questions }
    }, [dataContainer])

    const [selectedQuestion, setSelectedQuestion] = useState(questions[0])

    return (
        <div className="h-full bg-white rounded-b-lg grow flex flex-col min-h-[300px] p-5 gap-4">
            <div>
                <p className="text-4xl text-main font-bold">{dataContainer.firstname}</p>
                <Link
                    className="text-md text-blue-500 hover:underline"
                    to={dataContainer?.domain_name}
                >
                    {dataContainer.domain_name}
                </Link>
            </div>

            <div className="w-full">
                <div
                    //   onClick={() => setDisplay(!display)}
                    className="transition-all duration-200 delay-100 cursor-pointer flex justify-between p-4 w-full text-white bg-main rounded-md shadow-md animate_fade_up relative hover:bg-opacity-100 z-10"
                >
                    <div className="w-full">
                        <div className="flex items-center gap-4">
                            <span className="text-xl">
                                <IoLogoWechat />
                            </span>
                            <p>General SnapIntel</p>
                        </div>
                    </div>
                    <p className="border-black border px-2 rounded-full font-bold shadow-md">
                        {responses?.length ?? 0}
                    </p>
                </div>
                <div className="w-full px-10 pt-2">
                    <ol>
                        {defaultQuestions &&
                            questions.map((question, index) => (
                                <li
                                    key={index}
                                    className="list-decimal text-sm hover:underline hover:text-main cursor-pointer"
                                    onClick={() => setSelectedQuestion(question)}
                                >
                                    <span
                                        className={`${
                                            question?.question === selectedQuestion.question &&
                                            'font-bold underline text-main'
                                        }`}
                                    >
                                        {question?.question}
                                    </span>
                                </li>
                            ))}
                    </ol>

                    <div className="w-full custom-scroll py-5">
                        <div className="w-full min-h-[200px] h-fit flex flex-col ">
                            <GeneralSnapIntel
                                selectedQuestion={selectedQuestion}
                                questions={questions}
                                responses={responses}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const GeneralSnapIntelDisplay = ({ selectedQuestion }) => {
    return (
        <div className="h-full bg-white rounded-b-lg grow flex flex-col min-h-[300px] p-5 gap-4">
            <div className="text-xl text-main font-bold">{selectedQuestion?.question}</div>

            <div className="w-full">
                <div className="w-full px-10 pt-2">
                    <div className="w-full custom-scroll py-5">
                        <div className="w-full min-h-[200px] h-fit flex flex-col ">
                            <GeneralSnapIntel
                                selectedQuestion={selectedQuestion ?? ''}
                                responses={selectedQuestion?.responses ?? []}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
