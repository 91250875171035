import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'

export const AiModal = ({setIsModalOpen,confirmDownload,}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white rounded-lg shadow-lg p-6 w-96 relative">
        <p
            className="absolute top-2 right-2 text-lg opacity-70 hover:opacity-100 text-slate-500 cursor-pointer"
            onClick={() => setIsModalOpen(false)}
        >
            <AiOutlineCloseCircle />
        </p>
        <h2 className="text-lg font-semibold text-center mb-4">
            Do you want your file to be summarized by AI?
        </h2>
        <div className="flex justify-center gap-4">
            <button
                className="bg-main text-white px-4 py-2 rounded-md hover:bg-main-dark transition"
                onClick={() => confirmDownload(true)}
            >
                Yes
            </button>
            <button
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition"
                onClick={() => confirmDownload(false)}
            >
                No
            </button>
        </div>
    </div>
</div>  )
}
