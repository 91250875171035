import { axiosAuthInstance } from 'services/axiosAuth'
import { axiosInstance } from '../../axios'

export const loginUser = async (data) => {
    const response = await axiosInstance.post('/api/v1/account/login', data)
    return response.data
}

export const logoutUser = async () => {
    const token = localStorage.getItem('token')

    await axiosInstance
        .post('api/v1/account/logout', {
            token: token,
        })
        .then(() => {
            localStorage.clear()
        })
        .catch((error) => {
            console.log(error)
        })
        .finally(() => {
            window.location.replace(`${process.env.REACT_APP_LOCAL_AUTH_SERVER}/logout_user`)
        })
}

export const GetAccessTokenApi = async (code) => {
    const response = await axiosAuthInstance.post(
        'oauth/token',
        {
            grant_type: 'authorization_code',
            client_id: process.env.REACT_APP_LOCAL_CLIENT_ID,
            client_secret: process.env.REACT_APP_LOCAL_CLIENT_SERCRET,
            redirect_uri: process.env.REACT_APP_LOCAL_REDIRECT_URL + '/oauth/callback',
            code: code,
        },
        {
            headers: { 'Content-Type': 'multipart/form-data' },
        }
    )

    return response
}

export const GetUserInfoApi = async () => {
    const response = await axiosAuthInstance.get('user_info')
    return response
}

export const GetUserTokenFromNexusApi = async (user_info) => {
    const response = await axiosInstance.post('api/v1/token/get', { user_info })
    return response
}
