import React, { useState } from 'react'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import ImageView from 'components/ImageView'
import { FaFileDownload } from 'react-icons/fa'
import { LoadingComponent } from 'components/LoadingComponent'
import { getExportResponses } from 'services/request/Exports'
import { useMutation } from 'react-query'
import { NoData } from 'components/NoData'
import { ChatMessage } from 'components/Responses/FilteredResponse'
import ResponseMessage from 'components/Responses/FilteredReplies'

function CompaniesGeneralDiscussion({ generalDiscussionToggle, selectedEvent, isLoading }) {
    const [selectedResponse, setSelectedResponse] = useState(null)
    const [selectedImage, setSelectedImage] = useState()
    const { mutate: ExportData } = useMutation(['GetExportApi'], (data) => {
        getExportResponses(data)
    })
    const [showChat, setShowChat] = useState(false)
    const toggleChat = (chatId) => {
        setShowChat((prev) => ({
            ...prev,
            [chatId]: !prev[chatId],
        }))
    }
    const [showReply, setShowReply] = useState({})

    const toggleReply = (replyIndex) =>
        setShowReply((prev) => ({
            ...prev,
            [replyIndex]: !prev[replyIndex],
        }))
    const exportAllData = (event) => {
        const data = {
            event_id: event.event_id,
            question_id: null,
            type: 0,
            event: event,
        }
        ExportData(data)
    }

    if (isLoading) {
        return (
            <div className="w-full h-full justify-center items-center">
                <LoadingComponent
                    className="w-full justify-center items-center"
                    title={'General Discussion'}
                />
            </div>
        )
    }
    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 break-all hover:underline font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }

    return (
        <div className="w-3/4 bg-white rounded-md overflow-y-auto min-h-full h-[100%] sm:w-full grow">
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}
            <div className="w-full h-fit border-b flex border-main p-5 pb-4 border-opacity-40">
                <span className="w-full flex flex-col  items-center text-xl text-main font-bold border-l-4 border-main pl-4">
                    <p className="w-full text-left"> GENERAL DISCUSSION</p>
                    <span className="w-full text-left text-lg">{selectedEvent?.event_name}</span>
                </span>
                <p
                    onClick={() => exportAllData(selectedEvent)}
                    className="text-lg flex flex-col justify-between items-center text-main font-bold cursor-pointer hover:text-xl"
                >
                    <FaFileDownload />
                    <p className="text-sm">Download</p>
                </p>
            </div>

            <div className="w-full px-5 py-3">
                {generalDiscussionToggle && generalDiscussionToggle.length > 0 ? (
                    generalDiscussionToggle.map((response, responseIndex) => (
                        <div
                            // onClick={() => handleQuestionSelect(response)}
                            className=" cursor-pointer text-sm border-b p-2"
                            key={responseIndex}
                        >
                            <span className="text-sm font-bold ">{response?.user?.fullname}</span>
                            <div className="text-xs gap-2 flex flex-col w-fit max-w-[75%] justify-start items-start text-left bg-[#cbcbcb] bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                <ChatMessage
                                    key={response.id}
                                    chat={response}
                                    showChat={showChat}
                                    toggleChat={toggleChat}
                                    setSelectedImage={setSelectedImage}
                                    makeUrlsClickable={makeUrlsClickable}
                                />
                            </div>

                            <div className="w-full flex flex-col gap-2">
                                <div className="px-10 flex gap-2">
                                    {response.responses?.length > 0 && (
                                        <div className="flex">
                                            <span className="rotate-90 text-base justify-center items-center ">
                                                <MdOutlineSubdirectoryArrowLeft />
                                            </span>
                                            <span
                                                onClick={() =>
                                                    setSelectedResponse(
                                                        selectedResponse === response
                                                            ? null
                                                            : response
                                                    )
                                                }
                                                className="cursor-pointer "
                                            >
                                                {selectedResponse === response ? 'Hide' : 'Show'}{' '}
                                                Replies
                                            </span>
                                        </div>
                                    )}
                                </div>
                                {selectedResponse === response &&
                                    response.responses?.length > 0 && (
                                        <React.Fragment>
                                            <div className="flex flex-col w-full ml-5 px-6 animate_fade_up">
                                                {response.responses.map((response) => (
                                                    <ResponseMessage
                                                        key={response.id}
                                                        response={response}
                                                        showReply={showReply}
                                                        toggleReply={toggleReply}
                                                        setSelectedImage={setSelectedImage}
                                                        makeUrlsClickable={makeUrlsClickable}
                                                    />
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    )}
                            </div>
                        </div>
                    ))
                ) : (
                    <NoData />
                )}
            </div>
        </div>
    )
}

export default CompaniesGeneralDiscussion
