import React, { useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import LoadingImage from '../../../assets/NexusBrain.png'
import DataTable from 'react-data-table-component'
import { LoadingComponent } from 'components/LoadingComponent'
import { searchValueAtom } from 'pages/MainDashboard'
import { useAtomValue } from 'jotai'
import Highlighter from 'react-highlight-words'

export const Users = ({ searchUsers, setUsersContainer, setTabs }) => {
    const [display, setDisplay] = useState(false)
    const [userHolder, setUserHolder] = useState()
    const searchValue = useAtomValue(searchValueAtom)

    const columns = [
        {
            name: 'Name',
            selector: (row) =>
                row.firstname + ` ${row.lastname && row.lastname != null ? row.lastname : ''}`,
            cell: (row) => (
                <div className="cursor-pointer" onClick={() => handleRowClicked(row)}>
                    <span
                        className={`${
                            userHolder === row && 'font-bold underline text-main'
                        } hover:underline`}
                    >
                        <Highlighter
                            searchWords={[searchValue]}
                            autoEscape={true}
                            textToHighlight={`${row.firstname} ${
                                row.lastname && row.lastname !== null ? row.lastname : ''
                            }`}
                        />
                    </span>
                </div>
            ),
        },
        {
            name: 'Email Address',
            selector: (row) => row.email,
            cell: (row) => (
                <div className="cursor-pointer" onClick={() => handleRowClicked(row)}>
                    <span
                        className={`${
                            userHolder === row && 'font-bold underline text-main'
                        } hover:underline `}
                    >
                        {' '}
                        {row.email}
                    </span>
                </div>
            ),
        },
    ]

    const handleRowClicked = (row) => {
        setUsersContainer(row)
        setUserHolder(row)
        setTabs(6)
    }

    return (
        <div className="w-full">
            <button
                onClick={() => {
                    setDisplay(!display)
                    setUsersContainer(searchUsers[0])
                    setTabs(6)
                }}
                disabled={searchUsers?.length == 0 && true}
                className="transition-all duration-200 cursor-pointer flex justify-between p-4 w-full bg-[#397637] text-white rounded-md shadow-md animate_fade_up relative hover:bg-main z-10"
            >
                <div className="flex items-center">
                    <span className="text-xl">
                        <BiChevronDown />
                    </span>
                    <p>Users</p>
                </div>
                {searchUsers ? (
                    <p className="border-white border px-2 rounded-full font-bold shadow-md">
                        <React.Fragment>{searchUsers?.length ?? 0} </React.Fragment>
                    </p>
                ) : (
                    <img
                        src={LoadingImage}
                        className="w-[25px] animate_slow_spin brightness-200 "
                    />
                )}
            </button>
            {display && (
                <React.Fragment>
                    <div className="transition-all duration-200  w-full bg-white shadow-md pt-4 pb-2 px-4 -mt-5 rounded-md animate_fade_up">
                        <DataTable
                            progressComponent={<LoadingComponent title={'Users'} />}
                            progressPending={!searchUsers}
                            data={searchUsers}
                            columns={columns}
                            fixedHeader
                            fixedHeaderScrollHeight="300px"
                            pagination
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export const UsersDisplay = ({ isLoading, dataContainer }) => {
    const searchValue = useAtomValue(searchValueAtom)

    return (
        <div className="h-full bg-white rounded-b-lg grow flex flex-col min-h-[300px] p-10">
            <p className="text-4xl text-main font-bold">
                <Highlighter
                    searchWords={[searchValue]}
                    autoEscape={true}
                    textToHighlight={`${dataContainer.firstname} ${dataContainer.lastname}`}
                />
            </p>
            {/* <p className="text-lg text-main font-bold">Company: Nexbridge</p> */}
            <div className="gap-4 flex flex-col pt-5">
                <div className="w-full">
                    <p className="text-xl text-main font-bold">
                        Groups of{' '}
                        <Highlighter
                            searchWords={[searchValue]}
                            autoEscape={true}
                            textToHighlight={`${dataContainer.firstname} ${dataContainer.lastname}`}
                        />
                    </p>
                    <ul className="pl-10 grid grid-cols-3">
                        {dataContainer.groups &&
                            dataContainer.groups.map((group, groupIndex) => (
                                <li key={groupIndex} className="text-darkgrey list-decimal">
                                    <Highlighter
                                        searchWords={[searchValue]}
                                        autoEscape={true}
                                        textToHighlight={group.group_name}
                                    />
                                </li>
                            ))}
                    </ul>
                </div>
                <div className="w-full">
                    <p className="text-xl text-main font-bold">Events Assigned</p>
                    <ul className="pl-10 grid grid-cols-2 w-full">
                        {dataContainer.groups &&
                            dataContainer.groups.map((group, groupIndex) => (
                                <div className="w-full" key={groupIndex}>
                                    {group.events &&
                                        group.events.map((event, eventIndex) => (
                                            <li
                                                className="list-decimal whitespace-normal px-5 text-darkgrey"
                                                key={eventIndex}
                                            >
                                                <Highlighter
                                                    searchWords={[searchValue]}
                                                    autoEscape={true}
                                                    textToHighlight={event?.event_name}
                                                />
                                            </li>
                                        ))}
                                </div>
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export const DefaultDisplay = ({ isLoading, dataContainer }) => {
    return (
        <div className="h-full bg-white rounded-b-lg grow flex flex-col min-h-[300px] p-7 ">
            {/* <p className="text-4xl text-main font-bold">Select a tab</p> */}
            <div className="items-center flex w-full justify-center h-full ">
                <span className="items-center text-4xl text-main w-fit h-fit py-28 justify-center transition-all animate-pulse duration-500 text-center font-bold">
                    Choose a tab on the left panel.
                </span>
            </div>
        </div>
    )
}
