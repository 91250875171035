import React, { useEffect, useMemo, useState } from 'react'
import MainNav from 'components/MainNav'
import DataTable from 'react-data-table-component'
import { GlobalSearchBar } from './GlobalSearchBar'
import { TbLayoutKanban } from 'react-icons/tb'
import { LoadingComponent } from 'components/LoadingComponent'
import { useQuery } from 'react-query'
import { getDashboardApi, getGoogleSearch, globalSearchApi } from 'services/request/Dashboard'
import Default from 'assets/New_Placeholder.png'
import { EventDetails } from './EventDetails'
import { CompaniesDisplay, GeneralSnapIntelDisplay } from 'components/AccordionDisplay/Companies'
import { QuestionsDisplay } from 'components/AccordionDisplay/Questions'
import {
    ResponsesDisplay,
    UpdateDisplay,
    UpdateDisplayGenSnap,
} from 'components/AccordionDisplay/Responses'
import { DefaultDisplay, UsersDisplay } from 'components/AccordionDisplay/Users'
import PDFIcon from '../../assets/pdf.png'
import { GlobalSearchAccordions } from './GlobalSearchAccordions'
import { useDebouncer } from 'utlilites/useDebouncer'
import { atom, useAtom, useAtomValue } from 'jotai'
import { authAtom } from 'store/authAtom'
import GeneralSnapIntel from 'pages/SubPages/GeneralSnapIntel'
import { NavLink } from 'react-router-dom'

export const GlobalSearchAtom = atom([])
export const searchValueAtom = atom('')

export const MainDashboard = () => {
    const [seledtedImage, setSelectedImage] = useState()
    const [searchValue, setSearchValue] = useState('')
    const [selectedView, setSelectedView] = useState()
    const [selectedData, setSelectedData] = useState(null)

    let data = null
    const storedData = localStorage.getItem('selectedData')
    if (storedData) {
        try {
            data = JSON.parse(storedData)
        } catch (e) {
            console.error('Error parsing JSON from localStorage:', e)
        }
    }

    const [generalSnapIntelView, setGeneralSnapIntelView] = useState()
    const { user } = useAtomValue(authAtom)
    const debouncedValue = useDebouncer(searchValue, 1000)
    const [viewChange, setViewChange] = useState(() => {
        const savedView = localStorage.getItem('viewChange')
        return savedView ? JSON.parse(savedView) : true
    })
    const [globalExport, setGlobalExport] = useAtom(GlobalSearchAtom)

    useEffect(() => {
        localStorage.setItem('viewChange', JSON.stringify(viewChange))
    }, [viewChange])

    const { data: EventResponse, isLoading: isEventLoading } = useQuery(
        ['getDashboardApi'],
        getDashboardApi
    )

    useEffect(() => {
        if (EventResponse?.result?.length) {
            const firstData = EventResponse.result[0]
            setSelectedData(firstData)
            setSelectedView(firstData.firstname ? 'updateGenSnap' : 'event')
            localStorage.setItem('selectedData', JSON.stringify(firstData))
        }
    }, [EventResponse])

    useEffect(() => {
        const toggle = JSON.parse(localStorage.getItem('genSnapToggle'))
        const data = localStorage.getItem('selectedData')
        if (toggle) {
            setSelectedView('updateGenSnap')
            if (data) {
                try {
                    setSelectedData(JSON.parse(data))
                } catch (e) {
                    console.error('Error parsing selectedData from localStorage:', e)
                    setSelectedData({})
                }
            } else {
                setSelectedData({})
            }
        }
    }, [])

    const { data: GlobalSearchResult, isLoading: isGlobalSearchLoading } = useQuery(
        ['GlobalSearchApi', debouncedValue],
        () => (debouncedValue ? globalSearchApi(debouncedValue) : null),
        {
            onSuccess: (data) => setGlobalExport({ data, selectedData }),
        }
    )

    const [startIndex, setStartIndex] = useState(1)
    const [isLoadingNextPage, setIsLoadingNextPage] = useState(false)
    const [searchType, setSearchType] = useState('searchTypeUndefined')
    const [sort, setSort] = useState('date')
    const [selectedFacet, setSelectedFacet] = useState('')

    const { data: searchData, isLoading } = useQuery(
        ['googleSearch', debouncedValue, startIndex, searchType, sort, selectedFacet],
        () =>
            getGoogleSearch({
                searchTerms: `${debouncedValue} ${selectedFacet}`,
                startIndex,
                searchType,
                sort,
            }),
        {
            keepPreviousData: true,
            enabled: !!debouncedValue,
            onSuccess: () => {
                setIsLoadingNextPage(false)
            },
        }
    )

    const items = searchData?.items || []
    const facets = searchData?.context?.facets || []

    const hasNextPage = searchData?.queries?.nextPage?.length > 0
    const hasPreviousPage = startIndex > 1

    const handleFacetClick = (facetLabel) => {
        setSelectedFacet(facetLabel)
        setStartIndex(1)
        setIsLoadingNextPage(true)
    }
    const handleNextPage = () => {
        const nextStartIndex = searchData?.queries?.nextPage[0]?.startIndex
        if (nextStartIndex) {
            setIsLoadingNextPage(true)
            setStartIndex(nextStartIndex)
        }
    }

    const handlePreviousPage = () => {
        const previousStartIndex = searchData?.queries?.request[0]?.startIndex - 10
        if (previousStartIndex > 0) {
            setIsLoadingNextPage(true)
            setStartIndex(previousStartIndex)
        }
    }
    const handleChangeSearchBar = (value) => {
        setSearchValue(value)

        setSelectedView('default')
    }

    const handleClearSearchBar = () => {
        setSearchValue('')
        setShowSearchResults(false)
        if (EventResponse?.result?.length) {
            const firstData = EventResponse.result[0]
            setSelectedView('updateGenSnap')
            handleSelectedData(firstData, false)
        }
    }

    useEffect(() => {
        if (!searchValue) {
            setSelectedView('updateGenSnap')
            setShowSearchResults(false)
        }
    }, [searchValue])

    const handleSelectedData = (data, isSnapIntelUpdate) => {
        setSelectedData(data)
        if (isSnapIntelUpdate) {
            setSelectedView('update')
        } else {
            setSelectedView(data?.event_name ? 'event' : 'updateGenSnap')
        }
        localStorage.setItem('selectedData', JSON.stringify(data))
        localStorage.setItem('genSnapToggle', true)
    }

    useEffect(() => {
        if (selectedData !== data) {
            handleSelectedData(data)
        }
    }, [])

    const customSortFunction = (rowA, rowB) => {
        const dateA = rowA.latest_response
            ? new Date(rowA.latest_response.created_at)
            : new Date(rowA.created_at)
        const dateB = rowB.latest_response
            ? new Date(rowB.latest_response.created_at)
            : new Date(rowB.created_at)
        return dateA - dateB
    }

    const columns = [
        {
            // id: 'id',
            name: viewChange ? 'Insights' : 'Sort by Name',
            sortable: true,
            selector: (row) => row.firstname || row.event_name,
            cell: (row) => (
                <>
                    {viewChange ? (
                        <div className="w-full h-fit flex justify-start items-start gap-x-2 min-h-[200px]">
                            <div className="h-full w-1/3 p-2 ">
                                {row.responses && row.responses.length > 0 ? (
                                    <div>
                                        {row.responses.some(
                                            (response) =>
                                                response?.attachments.length > 0 &&
                                                response?.attachments[0].type === 'image'
                                        ) ? (
                                            row.responses
                                                ?.filter((response) =>
                                                    response.attachments.some(
                                                        (attachment) => attachment.type === 'image'
                                                    )
                                                )
                                                .slice(0, 1)
                                                .map((response, index) => {
                                                    const imageAttachment =
                                                        response.attachments.find(
                                                            (attachment) =>
                                                                attachment.type === 'image'
                                                        )

                                                    if (imageAttachment) {
                                                        return (
                                                            <img
                                                                key={index}
                                                                src={imageAttachment.filename}
                                                                alt={imageAttachment.filename}
                                                                className="cursor-pointer transition-all duration-300 h-[200px] max-w-[300px] rounded-md hover:opacity-70 object-cover w-full hover:object-scale-down"
                                                            />
                                                        )
                                                    }

                                                    return null
                                                })
                                        ) : (
                                            <div>
                                                <img
                                                    src={Default}
                                                    className="cursor-pointer transition-all duration-300 h-[200px] max-w-[300px] rounded-md  object-cover w-full "
                                                    alt="Event Cover"
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <img
                                            src={Default}
                                            className="cursor-pointer transition-all duration-300 h-[200px] max-w-[300px] rounded-md  object-cover w-full "
                                            alt="Event Cover"
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="w-2/3 p-2">
                                {row.insight_type === 'gensnap' ? (
                                    <div onClick={() => handleSelectedData(row, false)}>
                                        <div className="flex w-full">
                                            <span
                                                onClick={() => {
                                                    setSelectedView(
                                                        row.firstname ? 'updateGenSnap' : 'event'
                                                    )
                                                    handleSelectedData(row)
                                                }}
                                                className={`cursor-pointer w-full font-bold text-main text-lg ${
                                                    selectedData?.firstname === row?.firstname &&
                                                    'underline'
                                                }`}
                                            >
                                                {row?.firstname}
                                            </span>
                                            <span className="text-main w-full opacity-85 justify-end text-right  font-semibold">
                                                General SnapIntel
                                            </span>
                                        </div>
                                        <p>
                                            {!viewChange ? (
                                                <div>
                                                    {row.latest_response
                                                        ? row.latest_response.created_at
                                                        : row.created_at}
                                                </div>
                                            ) : (
                                                <div>
                                                    <b>Date: </b>{' '}
                                                    {row.latest_response
                                                        ? row.latest_response.created_at
                                                        : row.created_at}
                                                </div>
                                            )}
                                        </p>
                                        {viewChange ? (
                                            <div className="truncate-multiline">
                                                <div className="mt-3">{row.domain_name}</div>
                                            </div>
                                        ) : (
                                            <p
                                                className="cursor-pointer hover:text-blue-600"
                                                onClick={() => {
                                                    setSelectedView('updateGenSnap')
                                                    handleSelectedData(row)
                                                }}
                                            >
                                                View Description
                                            </p>
                                        )}

                                        <div className="border p-2 rounded-md mt-2 shadow-sm hover:cursor-pointer">
                                            <span className="cursor-pointer font-bold text-main text-xs">
                                                General SnapIntel Update
                                                {/* {row.latest_response
                                                    ? row.latest_response.answer
                                                    : ''} */}
                                            </span>
                                            <p className="mb-1 text-[11px]">
                                                {row.latest_response.question
                                                    ? row.latest_response.question.question
                                                    : ''}
                                            </p>
                                            <p className="italic m-1 line-clamp-2 text-[11px] text-justify items-center">
                                                "
                                                {row.latest_response
                                                    ? row.latest_response.answer
                                                    : ''}
                                                "
                                            </p>

                                            <div>
                                                {row.latest_response &&
                                                row.latest_response.attachments &&
                                                row.latest_response.attachments[0] ? (
                                                    row.latest_response.attachments[0].type ===
                                                    'image' ? (
                                                        <NavLink
                                                            to={
                                                                row.latest_response.attachments[0]
                                                                    .filename
                                                            }
                                                            target="__blank"
                                                            className="transition-all duration-300 flex gap-4 h-fit w-full items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                        >
                                                            <img
                                                                src={
                                                                    row.latest_response
                                                                        .attachments[0].filename
                                                                }
                                                                alt="Attachment"
                                                                className="h-[150px]"
                                                                onClick={() =>
                                                                    setSelectedImage(
                                                                        row.latest_response
                                                                            .attachments[0].filename
                                                                    )
                                                                }
                                                            />
                                                            <p className="text-left text-xs break-all truncate-multiline-2">
                                                                {
                                                                    row.latest_response
                                                                        .attachments[0].filename
                                                                }
                                                            </p>
                                                        </NavLink>
                                                    ) : row.latest_response.attachments[0].type ===
                                                      'file' ? (
                                                        <NavLink
                                                            to={
                                                                row.latest_response.attachments[0]
                                                                    .filename
                                                            }
                                                            target="__blank"
                                                            className="transition-all duration-300 flex gap-4 h-fit w-full items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                        >
                                                            <img
                                                                className="w-8"
                                                                src={PDFIcon}
                                                                alt="TEXT_FILE"
                                                            />
                                                            <p className="text-left text-xs break-all truncate-multiline-2">
                                                                {
                                                                    row.latest_response
                                                                        .attachments[0].filename
                                                                }
                                                            </p>
                                                        </NavLink>
                                                    ) : null
                                                ) : null}
                                            </div>
                                            <p className="text-right text-[9px] mt-1">
                                                {row.latest_response.user
                                                    ? row.latest_response.user.fullname
                                                    : ''}
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="flex w-full">
                                            <span
                                                // onClick={() => {
                                                //     setSelectedView('event')
                                                //     handleSelectedData(row)
                                                // }}
                                                onClick={() => handleSelectedData(row, false)}
                                                className={`cursor-pointer w-full font-bold text-main text-lg ${
                                                    selectedData?.event_name === row?.event_name &&
                                                    'underline'
                                                }`}
                                            >
                                                {row?.event_name}
                                            </span>
                                            <span className="text-main w-1/4 opacity-85 justify-end text-right  font-semibold">
                                                Event
                                            </span>
                                        </div>
                                        <p>
                                            {!viewChange ? (
                                                <div>
                                                    {row.latest_response
                                                        ? row.latest_response.created_at
                                                        : row.created_at}
                                                </div>
                                            ) : (
                                                <div>
                                                    <b>Date: </b>{' '}
                                                    {
                                                        // row.latest_response
                                                        //     ? row.latest_response.created_at
                                                        //     :
                                                        row.created_at
                                                    }
                                                </div>
                                            )}
                                        </p>
                                        {viewChange ? (
                                            <div className="truncate-multiline">
                                                <div className="mt-3">{row.description}</div>
                                            </div>
                                        ) : (
                                            <p
                                                className="cursor-pointer hover:text-blue-600"
                                                onClick={() => {
                                                    setSelectedView('event')
                                                    handleSelectedData(row)
                                                }}
                                            >
                                                View Description
                                            </p>
                                        )}

                                        <div
                                            // onClick={() => {
                                            //     setSelectedView('update')
                                            //     handleSelectedData(row)
                                            // }}
                                            onClick={() => handleSelectedData(row, true)}
                                            className="border p-2 rounded-md mt-2 shadow-sm hover:cursor-pointer"
                                        >
                                            <span className="cursor-pointer font-bold text-main text-xs">
                                                SnapIntel Updates...
                                            </span>

                                            {row.responses && row.responses.length > 0 ? (
                                                <div>
                                                    <div className="max-h-[555px] w-full overflow-ellipsis">
                                                        {(() => {
                                                            for (
                                                                let i = row.responses.length - 1;
                                                                i >= 0;
                                                                i--
                                                            ) {
                                                                const response = row.responses[i]
                                                                if (response.type === 2) {
                                                                    return (
                                                                        <>
                                                                            {response.question && (
                                                                                <p className="mb-1 text-[11px]">
                                                                                    {
                                                                                        response
                                                                                            .question
                                                                                            .question
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                            {response.user && (
                                                                                <p className="text-[11px]">
                                                                                    {
                                                                                        response
                                                                                            .user
                                                                                            .fullname
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                            {response.attachments
                                                                                .length > 0 ? (
                                                                                <div>
                                                                                    <ul>
                                                                                        <li>
                                                                                            {response
                                                                                                .attachments[0]
                                                                                                .type ===
                                                                                            'image' ? (
                                                                                                <NavLink
                                                                                                    to={
                                                                                                        response
                                                                                                            .attachments[0]
                                                                                                            .filename
                                                                                                    }
                                                                                                    target="__blank"
                                                                                                    className="transition-all duration-300 flex gap-4 h-fit w-full items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                                >
                                                                                                    <img
                                                                                                        src={
                                                                                                            response
                                                                                                                .attachments[0]
                                                                                                                .filename
                                                                                                        }
                                                                                                        alt="Attachment"
                                                                                                        className="h-[150px]"
                                                                                                        onClick={() =>
                                                                                                            setSelectedImage(
                                                                                                                response
                                                                                                                    .attachments[0]
                                                                                                                    .filename
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                    <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                                        {
                                                                                                            response
                                                                                                                .attachments[0]
                                                                                                                .filename
                                                                                                        }
                                                                                                    </p>
                                                                                                </NavLink>
                                                                                            ) : response
                                                                                                  .attachments[0]
                                                                                                  .type ===
                                                                                              'file' ? (
                                                                                                <NavLink
                                                                                                    to={
                                                                                                        response
                                                                                                            .attachments[0]
                                                                                                            .filename
                                                                                                    }
                                                                                                    target="__blank"
                                                                                                    className="transition-all duration-300 flex gap-4 h-fit w-full items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                                >
                                                                                                    <img
                                                                                                        className="w-8"
                                                                                                        src={
                                                                                                            PDFIcon
                                                                                                        }
                                                                                                        alt="TEXT_FILE"
                                                                                                    />
                                                                                                    <p className="text-left text-xs break-all truncate-multiline-2">
                                                                                                        {
                                                                                                            response
                                                                                                                .attachments[0]
                                                                                                                .filename
                                                                                                        }
                                                                                                    </p>
                                                                                                </NavLink>
                                                                                            ) : null}
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            ) : null}

                                                                            {response.answer && (
                                                                                <p className="italic m-1 line-clamp-2 text-[11px] text-justify items-center">
                                                                                    "
                                                                                    {
                                                                                        response.answer
                                                                                    }
                                                                                    "
                                                                                </p>
                                                                            )}
                                                                            <p className="text-right text-[9px] mt-1">
                                                                                {
                                                                                    response.created_at
                                                                                }
                                                                            </p>
                                                                        </>
                                                                    )
                                                                }
                                                            }
                                                            return (
                                                                <p className="italic text-gray-400 text-center">
                                                                    "There's no recent update"
                                                                </p>
                                                            )
                                                        })()}
                                                    </div>
                                                </div>
                                            ) : (
                                                <p className="italic text-gray-400 text-center">
                                                    "There's no recent update"
                                                </p>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ) : (
                        <>
                            <p
                                onClick={() => {
                                    setSelectedView('event')
                                    handleSelectedData(row)
                                }}
                                className={`cursor-pointer font-bold ${
                                    (selectedData?.firstname === row.firstname &&
                                        selectedData?.id === row.id) ||
                                    (selectedData?.event_name === row.event_name &&
                                        selectedData?.id === row.id)
                                        ? 'underline'
                                        : ''
                                }`}
                            >
                                {row.firstname || row?.event_name}
                            </p>
                        </>
                    )}
                </>
            ),
        },

        viewChange === true
            ? ''
            : {
                  //   id: 'id',

                  name: viewChange ? null : 'Sort by Date',
                  sortable: true,
                  sortFunction: customSortFunction,
                  selector: 'created_at',
                  cell: (row) => (
                      <p
                          onClick={() => {
                              setSelectedView('event')
                              handleSelectedData(row)
                          }}
                          className="cursor-pointer font-bold"
                      >
                          {row.latest_response ? row.latest_response.created_at : row.created_at}
                      </p>
                  ),
              },
    ]
    const result = useMemo(() => EventResponse?.result ?? [], [EventResponse])

    const detailComponents = () => {
        const components = {
            event: (
                <EventDetails
                    selectedEvent={
                        selectedData ?? GlobalSearchResult?.result?.events?.[0] ?? result?.[0]
                    }
                    result={result}
                    isEventLoading={isEventLoading || isGlobalSearchLoading}
                />
            ),
            company:
                user?.role === 'admin' ? (
                    <CompaniesDisplay
                        dataContainer={selectedData}
                        defaultQuestions={GlobalSearchResult?.result?.default_questions ?? []}
                    />
                ) : (
                    <GeneralSnapIntelDisplay selectedQuestion={selectedData} />
                ),
            question: (
                <QuestionsDisplay dataContainer={selectedData} isLoading={isGlobalSearchLoading} />
            ),
            response: <ResponsesDisplay dataContainer={selectedData} />,
            update: <UpdateDisplay dataContainer={selectedData} />,
            updateGenSnap: <UpdateDisplayGenSnap dataContainer={selectedData} />,
            user: <UsersDisplay dataContainer={selectedData} />,
            default: <DefaultDisplay />,
        }

        const selectedComponent = components[selectedView] || null

        return selectedComponent
    }

    const [showSearchResults, setShowSearchResults] = useState(false)

    const handleToggle = () => {
        if (searchValue) {
            setShowSearchResults((prev) => !prev)
        }
    }

    return (
        <div className="flex flex-col overflow-x-hidden">
            <div className="opacity-50 fixed w-full h-full bg-dashboard bg-[length:600px_300px] bg-center -z-10 brightness-[.8]"></div>
            {generalSnapIntelView && (
                <GeneralSnapIntel onClose={() => setGeneralSnapIntelView(false)} />
            )}
            <div className="flex items-center space-x-2 p-2 ">
                <GlobalSearchBar
                    handleSearch={handleChangeSearchBar}
                    searchValue={searchValue}
                    clearSearch={handleClearSearchBar}
                    globalSearchResult={GlobalSearchResult?.result}
                    showSearchResults={showSearchResults}
                    handleToggle={handleToggle}
                    selectView={setSelectedView}
                    selectedData={handleSelectedData}
                    className="flex-grow"
                />
            </div>

            <div>
                {showSearchResults ? (
                    <div className="w-[80%] mx-auto flex flex-col items-center justify-center">
                        <div className="flex">
                            <button
                                className={`border-main border py-2 px-5 ${
                                    searchType === 'searchTypeUndefined'
                                        ? 'text-main bg-white'
                                        : 'text-white bg-main'
                                }`}
                                onClick={() => setSearchType('searchTypeUndefined')}
                            >
                                Web
                            </button>
                            <button
                                className={`border-main border py-2 px-5 ${
                                    searchType === 'image'
                                        ? 'text-main bg-white'
                                        : 'text-white bg-main'
                                }`}
                                onClick={() => setSearchType('image')}
                            >
                                Image
                            </button>
                        </div>

                        <div className="flex-wrap flex items-center w-full justify-center mb-2 gap-2">
                            <button
                                className={`p-2 mt-2 ${
                                    !selectedFacet
                                        ? 'border-main text-main bg-white border-b-4 border'
                                        : 'bg-main text-white'
                                }`}
                                onClick={() => handleFacetClick('')}
                            >
                                All results
                            </button>
                            {facets.map((facetArray, index) => (
                                <div key={facetArray[0].id || index} className="mt-2 flex gap-3">
                                    <button
                                        className={`flex border p-2 ${
                                            selectedFacet === facetArray[0].label_with_op
                                                ? 'border-main text-main bg-white border-b-4 border'
                                                : 'bg-main text-white'
                                        }`}
                                        onClick={() =>
                                            handleFacetClick(facetArray[0].label_with_op)
                                        }
                                    >
                                        {facetArray[0].anchor}
                                    </button>
                                </div>
                            ))}
                        </div>

                        <div className="flex w-full">
                            <p className="w-full justify-start flex">
                                About {searchData?.searchInformation?.formattedTotalResults} results
                                ({searchData?.searchInformation?.formattedSearchTime} seconds)
                            </p>

                            <label className="w-full justify-end mt-1 mr-1 flex">Sort by:</label>
                            <select
                                name="sort"
                                className="outline-none bg-main text-white p-1 mb-2 rounded-md"
                                value={sort}
                                onChange={(e) => setSort(e.target.value)}
                            >
                                <option value="date">Date</option>
                                <option value="">Relevance</option>
                            </select>
                        </div>

                        <div className="w-full flex flex-wrap gap-2 bg-white break-all">
                            {isLoading || isLoadingNextPage ? (
                                <div className="text-base w-full">
                                    <LoadingComponent title={'Google Results'} />
                                </div>
                            ) : items?.length ? (
                                items.map((item, index) => (
                                    <div
                                        key={item.id || index}
                                        className={`result-item ${
                                            searchType !== 'image' ? 'w-full' : ''
                                        }`}
                                    >
                                        {searchType === 'image' ? (
                                            <div className="image-result">
                                                <a
                                                    href={item.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="block rounded-lg p-2 overflow-hidden transform hover:scale-105 transition-transform"
                                                >
                                                    <img
                                                        src={item.image?.thumbnailLink}
                                                        alt={item.title || 'Image'}
                                                        className="object-cover w-full h-40 rounded-md"
                                                    />
                                                    <div className="mt-2">
                                                        <p className="font-semibold text-sm text-gray-800">
                                                            {item.title}
                                                        </p>
                                                        <p className="text-xs text-gray-500">
                                                            {item.snippet}
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        ) : (
                                            <div className=" w-full">
                                                <p className="text-lg font-semibold">
                                                    <a
                                                        href={item.link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {item.title}
                                                    </a>
                                                </p>
                                                <p>
                                                    <a
                                                        href={item.link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-stone-500"
                                                    >
                                                        {item.link}
                                                    </a>
                                                </p>
                                                <div className="flex gap-3">
                                                    {item.pagemap?.cse_thumbnail && (
                                                        <img
                                                            className="object-cover w-fit h-20"
                                                            src={item.pagemap.cse_thumbnail[0]?.src}
                                                            alt={item.title || 'Thumbnail'}
                                                            width={
                                                                item.pagemap.cse_thumbnail[0]?.width
                                                            }
                                                            height={
                                                                item.pagemap.cse_thumbnail[0]
                                                                    ?.height
                                                            }
                                                        />
                                                    )}
                                                    <p>{item.snippet}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p>No results found.</p>
                            )}
                        </div>

                        <div className="flex gap-4 mt-2 mb-4">
                            {hasPreviousPage && (
                                <button
                                    onClick={handlePreviousPage}
                                    className="px-4 py-2 bg-gray-500 text-white rounded"
                                    disabled={isLoadingNextPage}
                                >
                                    Previous Page
                                </button>
                            )}
                            {hasNextPage && (
                                <button
                                    onClick={handleNextPage}
                                    className="px-4 py-2 bg-main text-white rounded"
                                    disabled={isLoadingNextPage}
                                >
                                    {isLoadingNextPage ? 'Loading...' : 'Next Page'}
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="w-full flex py-4 pl-4 pr-8 gap-x-4 md:flex-col sm:flex-col">
                        <div className="w-1/2 h-full rounded-lg custom-scroll sm:w-full md:w-full sm:mb-10 md:mb-10">
                            <div className="h-fit w-full grow flex md:flex-col sm:flex-col">
                                <MainNav onDisplay={() => setGeneralSnapIntelView(true)} />
                            </div>
                            <div className="h-fit flex flex-col w-full overflow-y-auto gap-y-2 mt-4 rounded-sm overflow-hidden">
                                {!searchValue ? (
                                    <React.Fragment>
                                        <div className={viewChange && 'Card-Styling'}>
                                            <div className="w-full bg-white rounded-t-md px-4 pb-6 pt-2 -mb-4  z-10 flex">
                                                <p className="text-main text-lg justify-center items-center p-2 font-bold select-none">
                                                    Insights
                                                </p>
                                                <div className="w-full flex justify-end items-center gap-2 ">
                                                    <div className="flex w-1/3 items-center justify-end  gap-2">
                                                        <button
                                                            className="text-white text-lg bg-main px-3 py-2 rounded-md flex items-center gap-2 whitespace-nowrap"
                                                            onClick={() =>
                                                                setViewChange(!viewChange)
                                                            }
                                                        >
                                                            <TbLayoutKanban />
                                                            <span className="text-sm">View</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <DataTable
                                                progressComponent={
                                                    <LoadingComponent title={'Entries'} />
                                                }
                                                progressPending={isEventLoading}
                                                keyField="id"
                                                data={result} // data={EventResponse?.result ?? []}
                                                columns={columns}
                                                fixedHeader={true}
                                                fixedHeaderScrollHeight="65vh"
                                                pagination
                                                paginationRowsPerPageOptions={[
                                                    10, 15, 20, 25, 50, 100,
                                                ]}
                                            />
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <GlobalSearchAccordions
                                            globalSearchResult={GlobalSearchResult?.result}
                                            selectView={setSelectedView}
                                            selectedData={handleSelectedData}
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="w-1/2 h-full rounded-lg mb-10 shadow-md md:w-full sm:w-full overflow-y-auto custom-scroll">
                            {detailComponents()}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
