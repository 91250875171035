import React, { Fragment, useEffect, useRef, useState } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaFile, FaWindowMinimize } from 'react-icons/fa'
import PDFIcon from '../../assets/pdf.png'
import { Link, NavLink } from 'react-router-dom'
import { DownloadProgress } from 'components/DownloadingComponent'
import ImageView from 'components/ImageView'
import { GoFile } from 'react-icons/go'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'

export const AnalyticsExport = ({ data, dataQuest, dataReact, onClose }) => {
    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState()

    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save()
        }
    }
    const downloadPDF = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
        exportPDFWithComponent()
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onClose()
            }
        }

        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [onClose])

    const [selectedImage, setSelectedImage] = useState()

    const [displayUser, setDisplayUser] = useState(true)
    const [displayQuest, setDisplayQuest] = useState(true)
    const [displayReact, setDisplayReact] = useState(true)
    const [dataContainer, setDataContainer] = useState(dataQuest)
    const [selectedItem, setSelectedItem] = useState()
    useEffect(() => {
        if (data) {
            const initialStatus = data.reduce((acc, _, index) => {
                acc[index] = true
                return acc
            }, {})

            setChatDisplayStatus(initialStatus)
            setKiqsDisplayStatus(initialStatus)
            setSnapDisplayStatus(initialStatus)
        }
    }, [data])

    const [chatDisplayStatus, setChatDisplayStatus] = useState({})
    const [kiqsDisplayStatus, setKiqsDisplayStatus] = useState({})
    const [snapDisplayStatus, setSnapDisplayStatus] = useState({})

    const toggleDisplay = (type, userIndex) => {
        switch (type) {
            case 'chat':
                setChatDisplayStatus((prevStatus) => ({
                    ...prevStatus,
                    [userIndex]: !prevStatus[userIndex],
                }))
                break
            case 'kiqs':
                setKiqsDisplayStatus((prevStatus) => ({
                    ...prevStatus,
                    [userIndex]: !prevStatus[userIndex],
                }))
                break
            case 'snap':
                setSnapDisplayStatus((prevStatus) => ({
                    ...prevStatus,
                    [userIndex]: !prevStatus[userIndex],
                }))
                break
            default:
                break
        }
    }
    const makeUrlsClickable = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-600 hover:underline break-all font-bold"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }

    return (
        <div className=" top-0 bottom-0  right-0 w-full overflow-x-hidden h-screen bg-white z-50 pt-4 flex flex-col justify-start items-center">
            <div className="w-full flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
                {loading ? (
                    <DownloadProgress />
                ) : (
                    <div className="w-full flex items-center justify-between ">
                        <button
                            onClick={() => downloadPDF()}
                            className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                        >
                            Download PDF
                        </button>

                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={onClose}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>
            <PDFExport fileName={'Analytics PDF'} ref={pdfExportComponent} paperSize="A4">
                {selectedImage != null && (
                    <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
                )}
                <div className="w-full items-center justify-center flex flex-col">
                    <div className=" items-center mb-7 flex flex-col gap-2 p-2 w-[440px] sm:w-full md:w-full ">
                        <div className="w-full text-white rounded-md h-fit bg-main flex items-center justify-between p-2">
                            <p className=" font-semibold text-lg italic">
                                Users with Most Response
                            </p>
                            <span
                                onClick={() => setDisplayUser(!displayUser)}
                                className="font-normal mb-1 cursor-pointer hover:scale-110"
                            >
                                <FaWindowMinimize style={{ color: '#fff' }} />
                            </span>
                        </div>
                        {!displayUser && (
                            <p className="text-[.6rem] w-full text-center italic">
                                User With Most Responses is Hidden
                            </p>
                        )}
                        {displayUser && (
                            <div className="w-full items-center justify-center flex flex-col">
                                {data &&
                                    data.map((user, userIndex) => (
                                        <div className="" key={userIndex}>
                                            <div
                                                className="w-full justify-between text-sm flex items-center shadow-md rounded-md py-2 px-2 bg-[#e7e7e7] cursor-pointer"
                                                onClick={() => {
                                                    toggleDisplay('chat', userIndex)
                                                    toggleDisplay('kiqs', userIndex)
                                                    toggleDisplay('snap', userIndex)
                                                }}
                                            >
                                                <p>
                                                    <span className="text-xs font-semibold text-main">
                                                        {user.companybelong.firstname}:
                                                    </span>
                                                    <span className="text-xs font-semibold">
                                                        {user.fullname} |{' '}
                                                    </span>
                                                    <span className="text-xs font-semibold">
                                                        {user?.responses_count}
                                                    </span>
                                                </p>
                                                <span className="font-normal cursor-pointer mb-1 mr-1 hover:scale-110">
                                                    <FaWindowMinimize
                                                        style={{ color: '#000000' }}
                                                    />
                                                </span>
                                            </div>
                                            <div className="w-full items-center justify-center">
                                                <>
                                                    <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-[440px] sm:w-full md:w-full text-white bg-main rounded-md shadow-md">
                                                        <span className="flex">CHATROOM</span>
                                                        <span
                                                            onClick={() =>
                                                                toggleDisplay('chat', userIndex)
                                                            }
                                                            className="font-normal cursor-pointer hover:scale-110"
                                                        >
                                                            <FaWindowMinimize
                                                                style={{ color: '#fff' }}
                                                            />
                                                        </span>
                                                    </p>

                                                    {chatDisplayStatus[userIndex] &&
                                                        user?.chatroom_responses &&
                                                        user?.chatroom_responses?.map(
                                                            (convo, convoIndex) => (
                                                                <div
                                                                    className="w-full text-xs rounded-md bg-gray-200 mb-1 shadow-sm px-5 py-3"
                                                                    key={convoIndex}
                                                                >
                                                                    <p
                                                                        className="text-sm text-justify break-words"
                                                                        style={{
                                                                            whiteSpace: 'pre-line',
                                                                        }}
                                                                    >
                                                                        {convo.answer &&
                                                                            makeUrlsClickable(
                                                                                convo.answer
                                                                            ).map((part, index) => (
                                                                                <React.Fragment
                                                                                    key={index}
                                                                                >
                                                                                    {part}
                                                                                </React.Fragment>
                                                                            ))}
                                                                    </p>
                                                                    <div className="w-full">
                                                                        {convo.attachments && (
                                                                            <>
                                                                                {convo.attachments.filter(
                                                                                    (attach) =>
                                                                                        attach.type ===
                                                                                        'image'
                                                                                ).length > 0 && (
                                                                                    <div className="grid grid-cols-4 gap-2">
                                                                                        {convo.attachments
                                                                                            .filter(
                                                                                                (
                                                                                                    attach
                                                                                                ) =>
                                                                                                    attach.type ===
                                                                                                    'image'
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    attach
                                                                                                ) => (
                                                                                                    <div
                                                                                                        key={
                                                                                                            attach.id
                                                                                                        }
                                                                                                        className="overflow-hidden"
                                                                                                    >
                                                                                                        <img
                                                                                                            alt={
                                                                                                                attach.filename
                                                                                                            }
                                                                                                            src={
                                                                                                                attach.filename
                                                                                                            }
                                                                                                            className="object-cover w-full h-32"
                                                                                                        />
                                                                                                        <Link
                                                                                                            to={
                                                                                                                attach.filename
                                                                                                            }
                                                                                                            target="_blank"
                                                                                                            className="flex mt-1"
                                                                                                        >
                                                                                                            <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                Click
                                                                                                                to
                                                                                                                view
                                                                                                            </p>
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                    </div>
                                                                                )}

                                                                                {/* Display non-image attachments separately */}
                                                                                {convo.attachments.filter(
                                                                                    (attach) =>
                                                                                        attach.type !==
                                                                                        'image'
                                                                                ).length > 0 && (
                                                                                    <div>
                                                                                        {convo.attachments
                                                                                            .filter(
                                                                                                (
                                                                                                    attach
                                                                                                ) =>
                                                                                                    attach.type !==
                                                                                                    'image'
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    attach
                                                                                                ) => (
                                                                                                    <div
                                                                                                        className="h-28 w-full py-5 flex break-all text-xs"
                                                                                                        key={
                                                                                                            attach.id
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            alt="PDF Icon"
                                                                                                            className="h-full w-fit"
                                                                                                            src={
                                                                                                                PDFIcon
                                                                                                            }
                                                                                                        />
                                                                                                        <div className="w-full">
                                                                                                            <p className="font-bold text-darkgrey">
                                                                                                                Filename:
                                                                                                            </p>
                                                                                                            <Link
                                                                                                                className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                                                to={
                                                                                                                    attach.filename
                                                                                                                }
                                                                                                                target="__blank"
                                                                                                            >
                                                                                                                {
                                                                                                                    attach.filename
                                                                                                                }
                                                                                                            </Link>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                </>
                                                <>
                                                    <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-[440px] sm:w-full md:w-full text-white bg-main rounded-md shadow-md">
                                                        <span className="flex">MY KIQS</span>
                                                        <span
                                                            onClick={() =>
                                                                toggleDisplay('kiqs', userIndex)
                                                            }
                                                            className="font-normal cursor-pointer hover:scale-110"
                                                        >
                                                            <FaWindowMinimize
                                                                style={{ color: '#fff' }}
                                                            />
                                                        </span>
                                                    </p>

                                                    {kiqsDisplayStatus[userIndex] &&
                                                        user?.mykiqs_responses &&
                                                        user?.mykiqs_responses?.map(
                                                            (convo, convoIndex) => (
                                                                <div
                                                                    className="w-full text-xs rounded-md bg-gray-200 mb-1 shadow-sm px-5 py-3"
                                                                    key={convoIndex}
                                                                >
                                                                    <p
                                                                        className="text-sm text-justify break-words"
                                                                        style={{
                                                                            whiteSpace: 'pre-line',
                                                                        }}
                                                                    >
                                                                        {convo.answer &&
                                                                            makeUrlsClickable(
                                                                                convo.answer
                                                                            ).map((part, index) => (
                                                                                <React.Fragment
                                                                                    key={index}
                                                                                >
                                                                                    {part}
                                                                                </React.Fragment>
                                                                            ))}
                                                                    </p>
                                                                    <div className="w-full">
                                                                        {convo.attachments && (
                                                                            <>
                                                                                {convo.attachments.filter(
                                                                                    (attach) =>
                                                                                        attach.type ===
                                                                                        'image'
                                                                                ).length > 0 && (
                                                                                    <div className="grid grid-cols-4 gap-2">
                                                                                        {convo.attachments
                                                                                            .filter(
                                                                                                (
                                                                                                    attach
                                                                                                ) =>
                                                                                                    attach.type ===
                                                                                                    'image'
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    attach
                                                                                                ) => (
                                                                                                    <div
                                                                                                        key={
                                                                                                            attach.id
                                                                                                        }
                                                                                                        className="overflow-hidden"
                                                                                                    >
                                                                                                        <img
                                                                                                            alt={
                                                                                                                attach.filename
                                                                                                            }
                                                                                                            src={
                                                                                                                attach.filename
                                                                                                            }
                                                                                                            className="object-cover w-full h-32"
                                                                                                        />
                                                                                                        <Link
                                                                                                            to={
                                                                                                                attach.filename
                                                                                                            }
                                                                                                            target="_blank"
                                                                                                            className="flex mt-1"
                                                                                                        >
                                                                                                            <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                Click
                                                                                                                to
                                                                                                                view
                                                                                                            </p>
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                    </div>
                                                                                )}

                                                                                {convo.attachments.filter(
                                                                                    (attach) =>
                                                                                        attach.type !==
                                                                                        'image'
                                                                                ).length > 0 && (
                                                                                    <div>
                                                                                        {convo.attachments
                                                                                            .filter(
                                                                                                (
                                                                                                    attach
                                                                                                ) =>
                                                                                                    attach.type !==
                                                                                                    'image'
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    attach
                                                                                                ) => (
                                                                                                    <div
                                                                                                        className="h-28 w-full py-5 flex break-all text-xs"
                                                                                                        key={
                                                                                                            attach.id
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            alt="PDF Icon"
                                                                                                            className="h-full w-fit"
                                                                                                            src={
                                                                                                                PDFIcon
                                                                                                            }
                                                                                                        />
                                                                                                        <div className="w-full">
                                                                                                            <p className="font-bold text-darkgrey">
                                                                                                                Filename:
                                                                                                            </p>
                                                                                                            <Link
                                                                                                                className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                                                to={
                                                                                                                    attach.filename
                                                                                                                }
                                                                                                                target="__blank"
                                                                                                            >
                                                                                                                {
                                                                                                                    attach.filename
                                                                                                                }
                                                                                                            </Link>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                </>
                                                <>
                                                    <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-[440px] sm:w-full md:w-full text-white bg-main rounded-md shadow-md">
                                                        <span className="flex">SNAPINTEL</span>
                                                        <span
                                                            onClick={() =>
                                                                toggleDisplay('snap', userIndex)
                                                            }
                                                            className="font-normal cursor-pointer hover:scale-110"
                                                        >
                                                            <FaWindowMinimize
                                                                style={{ color: '#fff' }}
                                                            />
                                                        </span>
                                                    </p>

                                                    {snapDisplayStatus[userIndex] &&
                                                        user?.snapintel_responses &&
                                                        user?.snapintel_responses?.map(
                                                            (convo, convoIndex) => (
                                                                <div
                                                                    className="w-full text-xs rounded-md bg-gray-200 mb-1 shadow-sm px-5 py-3"
                                                                    key={convoIndex}
                                                                >
                                                                    <p
                                                                        className="text-sm text-justify break-words"
                                                                        style={{
                                                                            whiteSpace: 'pre-line',
                                                                        }}
                                                                    >
                                                                        {convo.answer &&
                                                                            makeUrlsClickable(
                                                                                convo.answer
                                                                            ).map((part, index) => (
                                                                                <React.Fragment
                                                                                    key={index}
                                                                                >
                                                                                    {part}
                                                                                </React.Fragment>
                                                                            ))}
                                                                    </p>
                                                                    <div className="w-full">
                                                                        {convo.attachments && (
                                                                            <>
                                                                                {convo.attachments.filter(
                                                                                    (attach) =>
                                                                                        attach.type ===
                                                                                        'image'
                                                                                ).length > 0 && (
                                                                                    <div className="grid grid-cols-4 gap-2">
                                                                                        {convo.attachments
                                                                                            .filter(
                                                                                                (
                                                                                                    attach
                                                                                                ) =>
                                                                                                    attach.type ===
                                                                                                    'image'
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    attach
                                                                                                ) => (
                                                                                                    <div
                                                                                                        key={
                                                                                                            attach.id
                                                                                                        }
                                                                                                        className="overflow-hidden"
                                                                                                    >
                                                                                                        <img
                                                                                                            alt={
                                                                                                                attach.filename
                                                                                                            }
                                                                                                            src={
                                                                                                                attach.filename
                                                                                                            }
                                                                                                            className="object-cover w-full h-32"
                                                                                                        />
                                                                                                        <Link
                                                                                                            to={
                                                                                                                attach.filename
                                                                                                            }
                                                                                                            target="_blank"
                                                                                                            className="flex mt-1"
                                                                                                        >
                                                                                                            <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                Click
                                                                                                                to
                                                                                                                view
                                                                                                            </p>
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                    </div>
                                                                                )}

                                                                                {convo.attachments.filter(
                                                                                    (attach) =>
                                                                                        attach.type !==
                                                                                        'image'
                                                                                ).length > 0 && (
                                                                                    <div>
                                                                                        {convo.attachments
                                                                                            .filter(
                                                                                                (
                                                                                                    attach
                                                                                                ) =>
                                                                                                    attach.type !==
                                                                                                    'image'
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    attach
                                                                                                ) => (
                                                                                                    <div
                                                                                                        className="h-28 w-full py-5 flex break-all text-xs"
                                                                                                        key={
                                                                                                            attach.id
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            alt="PDF Icon"
                                                                                                            className="h-full w-fit"
                                                                                                            src={
                                                                                                                PDFIcon
                                                                                                            }
                                                                                                        />
                                                                                                        <div className="w-full">
                                                                                                            <p className="font-bold text-darkgrey">
                                                                                                                Filename:
                                                                                                            </p>
                                                                                                            <Link
                                                                                                                className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                                                to={
                                                                                                                    attach.filename
                                                                                                                }
                                                                                                                target="__blank"
                                                                                                            >
                                                                                                                {
                                                                                                                    attach.filename
                                                                                                                }
                                                                                                            </Link>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                </>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                    <div className=" items-center mb-7 flex flex-col gap-2 p-2 w-[440px] sm:w-full md:w-full ">
                        <div className="w-full text-white rounded-md h-fit bg-main flex items-center justify-between p-2">
                            <p className=" font-semibold text-lg italic">
                                Questions with Most Replies
                            </p>
                            <span
                                onClick={() => setDisplayQuest(!displayQuest)}
                                className="font-normal mb-1 cursor-pointer hover:scale-110"
                            >
                                <FaWindowMinimize style={{ color: '#fff' }} />
                            </span>
                        </div>
                        {!displayQuest && (
                            <p className="text-[.6rem] w-full text-center italic">
                                Questions With Most Replies is Hidden
                            </p>
                        )}
                        {displayQuest && (
                            <>
                                <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                    <span className="flex">CHATROOM </span>
                                </p>
                                {dataContainer?.chatroom?.questions &&
                                    dataContainer?.chatroom?.questions.map(
                                        (chat_questions, index) => (
                                            <>
                                                <p className="text-[.7em] transition-all duration-300 px-4 py-2 my- w-full text-white text-left bg-main text-left rounded-md shadow-md  ">
                                                    {chat_questions?.question}
                                                </p>
                                                {chat_questions &&
                                                    chat_questions?.responses?.length > 0 && (
                                                        <div className="flex flex-col w-full px-6 gap-2">
                                                            {chat_questions?.responses.map(
                                                                (response) => (
                                                                    <>
                                                                        <p className="  w-full self-end text-[.7em] bg-white border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                                                            <span className="flex gap-2 mb-2 items-start">
                                                                                <p className="text-main font-bold">
                                                                                    {
                                                                                        response
                                                                                            ?.user
                                                                                            ?.fullname
                                                                                    }
                                                                                </p>
                                                                            </span>
                                                                            <p
                                                                                className="text-sm text-justify break-words"
                                                                                style={{
                                                                                    whiteSpace:
                                                                                        'pre-line',
                                                                                }}
                                                                            >
                                                                                {response.answer &&
                                                                                    makeUrlsClickable(
                                                                                        response.answer
                                                                                    ).map(
                                                                                        (
                                                                                            part,
                                                                                            index
                                                                                        ) => (
                                                                                            <React.Fragment
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    part
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    )}
                                                                            </p>

                                                                            {response.attachments && (
                                                                                <>
                                                                                    {response.attachments.filter(
                                                                                        (attach) =>
                                                                                            attach.type ===
                                                                                            'image'
                                                                                    ).length >
                                                                                        0 && (
                                                                                        <div className="grid grid-cols-3 gap-2">
                                                                                            {response.attachments
                                                                                                .filter(
                                                                                                    (
                                                                                                        attach
                                                                                                    ) =>
                                                                                                        attach.type ===
                                                                                                        'image'
                                                                                                )
                                                                                                .map(
                                                                                                    (
                                                                                                        attach
                                                                                                    ) => (
                                                                                                        <div
                                                                                                            key={
                                                                                                                attach.id
                                                                                                            }
                                                                                                            className="overflow-hidden"
                                                                                                        >
                                                                                                            <img
                                                                                                                alt={
                                                                                                                    attach.filename
                                                                                                                }
                                                                                                                src={
                                                                                                                    attach.filename
                                                                                                                }
                                                                                                                className="object-cover w-full h-32"
                                                                                                            />
                                                                                                            <Link
                                                                                                                to={
                                                                                                                    attach.filename
                                                                                                                }
                                                                                                                target="_blank"
                                                                                                                className="flex mt-1"
                                                                                                            >
                                                                                                                <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                    Click
                                                                                                                    to
                                                                                                                    view
                                                                                                                </p>
                                                                                                            </Link>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                        </div>
                                                                                    )}

                                                                                    {response.attachments.filter(
                                                                                        (attach) =>
                                                                                            attach.type !==
                                                                                            'image'
                                                                                    ).length >
                                                                                        0 && (
                                                                                        <div>
                                                                                            {response.attachments
                                                                                                .filter(
                                                                                                    (
                                                                                                        attach
                                                                                                    ) =>
                                                                                                        attach.type !==
                                                                                                        'image'
                                                                                                )
                                                                                                .map(
                                                                                                    (
                                                                                                        attach
                                                                                                    ) => (
                                                                                                        <div
                                                                                                            className="h-28 w-full py-5 flex break-all text-xs"
                                                                                                            key={
                                                                                                                attach.id
                                                                                                            }
                                                                                                        >
                                                                                                            <img
                                                                                                                alt="PDF Icon"
                                                                                                                className="h-full w-fit"
                                                                                                                src={
                                                                                                                    PDFIcon
                                                                                                                }
                                                                                                            />
                                                                                                            <div className="w-full">
                                                                                                                <p className="font-bold text-darkgrey">
                                                                                                                    Filename:
                                                                                                                </p>
                                                                                                                <Link
                                                                                                                    className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                                                    to={
                                                                                                                        attach.filename
                                                                                                                    }
                                                                                                                    target="__blank"
                                                                                                                >
                                                                                                                    {
                                                                                                                        attach.filename
                                                                                                                    }
                                                                                                                </Link>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </p>
                                                                        {response &&
                                                                            response?.responses
                                                                                ?.length > 0 && (
                                                                                <>
                                                                                    <div className="flex gap-2 self-end justify-end items-center mt-1 w-full mb-1">
                                                                                        {' '}
                                                                                        <div
                                                                                            style={{
                                                                                                color: '#777777',
                                                                                            }}
                                                                                            className="rotate-90 -z-0 text-left w-fit text-[.8rem] -mt-1"
                                                                                        >
                                                                                            <MdOutlineSubdirectoryArrowLeft />{' '}
                                                                                        </div>
                                                                                        <div className="w-fit text-[.7rem]">
                                                                                            Replied
                                                                                            to
                                                                                        </div>
                                                                                    </div>{' '}
                                                                                    {response?.responses &&
                                                                                        response?.responses.map(
                                                                                            (
                                                                                                response,
                                                                                                index
                                                                                            ) => (
                                                                                                <div className="w-full flex flex-col justify-end items-end">
                                                                                                    <p className=" w-[80%] self-end text-[.7em] bg-green-100 border px-4 py-2 shadow-sm rounded-md bg-opacity-70">
                                                                                                        <span className="flex gap-2 mb-2 items-center">
                                                                                                            <p className="text-main font-bold">
                                                                                                                {
                                                                                                                    response
                                                                                                                        ?.user
                                                                                                                        ?.fullname
                                                                                                                }
                                                                                                            </p>
                                                                                                        </span>
                                                                                                        <p
                                                                                                            className="text-sm text-justify break-words"
                                                                                                            style={{
                                                                                                                whiteSpace:
                                                                                                                    'pre-line',
                                                                                                            }}
                                                                                                        >
                                                                                                            {response.answer &&
                                                                                                                makeUrlsClickable(
                                                                                                                    response.answer
                                                                                                                ).map(
                                                                                                                    (
                                                                                                                        part,
                                                                                                                        index
                                                                                                                    ) => (
                                                                                                                        <React.Fragment
                                                                                                                            key={
                                                                                                                                index
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {
                                                                                                                                part
                                                                                                                            }
                                                                                                                        </React.Fragment>
                                                                                                                    )
                                                                                                                )}
                                                                                                        </p>

                                                                                                        {response.attachments && (
                                                                                                            <>
                                                                                                                {response.attachments.filter(
                                                                                                                    (
                                                                                                                        attach
                                                                                                                    ) =>
                                                                                                                        attach.type ===
                                                                                                                        'image'
                                                                                                                )
                                                                                                                    .length >
                                                                                                                    0 && (
                                                                                                                    <div className="grid grid-cols-3 gap-2 ">
                                                                                                                        {response.attachments
                                                                                                                            .filter(
                                                                                                                                (
                                                                                                                                    attach
                                                                                                                                ) =>
                                                                                                                                    attach.type ===
                                                                                                                                    'image'
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                                (
                                                                                                                                    attach
                                                                                                                                ) => (
                                                                                                                                    <div
                                                                                                                                        key={
                                                                                                                                            attach.id
                                                                                                                                        }
                                                                                                                                        className="overflow-hidden"
                                                                                                                                    >
                                                                                                                                        <img
                                                                                                                                            alt={
                                                                                                                                                attach.filename
                                                                                                                                            }
                                                                                                                                            src={
                                                                                                                                                attach.filename
                                                                                                                                            }
                                                                                                                                            className="object-cover w-full h-32"
                                                                                                                                        />
                                                                                                                                        <Link
                                                                                                                                            to={
                                                                                                                                                attach.filename
                                                                                                                                            }
                                                                                                                                            target="_blank"
                                                                                                                                            className="flex mt-1"
                                                                                                                                        >
                                                                                                                                            <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                                                Click
                                                                                                                                                to
                                                                                                                                                view
                                                                                                                                            </p>
                                                                                                                                        </Link>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            )}
                                                                                                                    </div>
                                                                                                                )}

                                                                                                                {response.attachments.filter(
                                                                                                                    (
                                                                                                                        attach
                                                                                                                    ) =>
                                                                                                                        attach.type !==
                                                                                                                        'image'
                                                                                                                )
                                                                                                                    .length >
                                                                                                                    0 && (
                                                                                                                    <div>
                                                                                                                        {response.attachments
                                                                                                                            .filter(
                                                                                                                                (
                                                                                                                                    attach
                                                                                                                                ) =>
                                                                                                                                    attach.type !==
                                                                                                                                    'image'
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                                (
                                                                                                                                    attach
                                                                                                                                ) => (
                                                                                                                                    <div
                                                                                                                                        className="h-28 w-full py-5 flex break-all text-xs"
                                                                                                                                        key={
                                                                                                                                            attach.id
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        <img
                                                                                                                                            alt="PDF Icon"
                                                                                                                                            className="h-full w-fit"
                                                                                                                                            src={
                                                                                                                                                PDFIcon
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                        <div className="w-full">
                                                                                                                                            <p className="font-bold text-darkgrey">
                                                                                                                                                Filename:
                                                                                                                                            </p>
                                                                                                                                            <Link
                                                                                                                                                className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                                                                                to={
                                                                                                                                                    attach.filename
                                                                                                                                                }
                                                                                                                                                target="__blank"
                                                                                                                                            >
                                                                                                                                                {
                                                                                                                                                    attach.filename
                                                                                                                                                }
                                                                                                                                            </Link>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            )}
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </p>
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                </>
                                                                            )}
                                                                    </>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                            </>
                                        )
                                    )}
                                <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                    <span className="flex">MY KIQS </span>
                                </p>
                                {dataContainer?.mykiqs?.questions &&
                                    dataContainer?.mykiqs?.questions.map(
                                        (kiqs_questions, index) => (
                                            <>
                                                <p className="text-[.7em] transition-all duration-300 px-4 py-2 my- w-full text-white text-left bg-main text-left rounded-md shadow-md  ">
                                                    {kiqs_questions?.question}
                                                </p>
                                                {kiqs_questions &&
                                                    kiqs_questions?.responses?.length > 0 && (
                                                        <div>
                                                            {/* <p> Replies : </p> */}
                                                            <div className="flex flex-col w-full ml-5 px-6 gap-2">
                                                                {kiqs_questions?.responses.map(
                                                                    (responses) => (
                                                                        <>
                                                                            <div className="border rounded-md pt-2 pl-2">
                                                                                <div className="">
                                                                                    {/* <span className=" font-bold">
                                                                                        {
                                                                                            responses
                                                                                                ?.user
                                                                                                ?.fullname
                                                                                        }
                                                                                        -
                                                                                    </span> */}

                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            responses.created_at
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div
                                                                                    className=" flex flex-col w-fit bg-opacity-30 p-2 break-all rounded-l-md rounded-tr-md shadow-sm leading-4 gap-1"
                                                                                    key={
                                                                                        responses.id
                                                                                    }
                                                                                >
                                                                                    {/* {responses.answer} */}
                                                                                    <p
                                                                                        className="text-sm text-justify break-words"
                                                                                        style={{
                                                                                            whiteSpace:
                                                                                                'pre-line',
                                                                                        }}
                                                                                    >
                                                                                        {responses.answer &&
                                                                                            makeUrlsClickable(
                                                                                                responses.answer
                                                                                            ).map(
                                                                                                (
                                                                                                    part,
                                                                                                    index
                                                                                                ) => (
                                                                                                    <React.Fragment
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            part
                                                                                                        }
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            )}
                                                                                    </p>

                                                                                    {responses.attachments && (
                                                                                        <>
                                                                                            {responses.attachments.filter(
                                                                                                (
                                                                                                    attach
                                                                                                ) =>
                                                                                                    attach.type ===
                                                                                                    'image'
                                                                                            )
                                                                                                .length >
                                                                                                0 && (
                                                                                                <div className="grid grid-cols-3 gap-2 text-[.7em]">
                                                                                                    {responses.attachments
                                                                                                        .filter(
                                                                                                            (
                                                                                                                attach
                                                                                                            ) =>
                                                                                                                attach.type ===
                                                                                                                'image'
                                                                                                        )
                                                                                                        .map(
                                                                                                            (
                                                                                                                attach
                                                                                                            ) => (
                                                                                                                <div
                                                                                                                    key={
                                                                                                                        attach.id
                                                                                                                    }
                                                                                                                    className="overflow-hidden"
                                                                                                                >
                                                                                                                    <img
                                                                                                                        alt={
                                                                                                                            attach.filename
                                                                                                                        }
                                                                                                                        src={
                                                                                                                            attach.filename
                                                                                                                        }
                                                                                                                        className="object-cover w-full h-32"
                                                                                                                    />
                                                                                                                    <Link
                                                                                                                        to={
                                                                                                                            attach.filename
                                                                                                                        }
                                                                                                                        target="_blank"
                                                                                                                        className="flex mt-1"
                                                                                                                    >
                                                                                                                        <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                            Click
                                                                                                                            to
                                                                                                                            view
                                                                                                                        </p>
                                                                                                                    </Link>
                                                                                                                </div>
                                                                                                            )
                                                                                                        )}
                                                                                                </div>
                                                                                            )}

                                                                                            {responses.attachments.filter(
                                                                                                (
                                                                                                    attach
                                                                                                ) =>
                                                                                                    attach.type !==
                                                                                                    'image'
                                                                                            )
                                                                                                .length >
                                                                                                0 && (
                                                                                                <div>
                                                                                                    {responses.attachments
                                                                                                        .filter(
                                                                                                            (
                                                                                                                attach
                                                                                                            ) =>
                                                                                                                attach.type !==
                                                                                                                'image'
                                                                                                        )
                                                                                                        .map(
                                                                                                            (
                                                                                                                attach
                                                                                                            ) => (
                                                                                                                <div
                                                                                                                    className="h-28 w-full py-5 flex break-all text-xs"
                                                                                                                    key={
                                                                                                                        attach.id
                                                                                                                    }
                                                                                                                >
                                                                                                                    <img
                                                                                                                        alt="PDF Icon"
                                                                                                                        className="h-full w-fit"
                                                                                                                        src={
                                                                                                                            PDFIcon
                                                                                                                        }
                                                                                                                    />
                                                                                                                    <div className="w-full">
                                                                                                                        <p className="font-bold text-darkgrey">
                                                                                                                            Filename:
                                                                                                                        </p>
                                                                                                                        <Link
                                                                                                                            className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                                                            to={
                                                                                                                                attach.filename
                                                                                                                            }
                                                                                                                            target="__blank"
                                                                                                                        >
                                                                                                                            {
                                                                                                                                attach.filename
                                                                                                                            }
                                                                                                                        </Link>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        )}
                                                                                                </div>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                            </>
                                        )
                                    )}
                                <p className="text-xs transition-all flex justify-between font-bold duration-300 px-4 py-2 my-2 w-full text-white text-left bg-main text-whitetext-left rounded-md shadow-md  ">
                                    <span className="flex">SNAPINTEL </span>
                                </p>
                                {dataContainer?.snapintel?.questions &&
                                    dataContainer?.snapintel?.questions.map(
                                        (snapintel_questions, index) => (
                                            <>
                                                <p className="text-[.7em] transition-all duration-300 px-4 py-2 my- w-full text-white text-left bg-main text-left rounded-md shadow-md  ">
                                                    {snapintel_questions?.question}
                                                </p>
                                                {snapintel_questions &&
                                                    snapintel_questions?.responses?.length > 0 && (
                                                        <div>
                                                            {/* <p> Replies : </p> */}
                                                            <div className="flex flex-col w-full ml-5 px-6 gap-2">
                                                                {snapintel_questions?.responses.map(
                                                                    (responses) => (
                                                                        <>
                                                                            <div className="border rounded-md pt-2 pl-2">
                                                                                <div className="">
                                                                                    {/* <span className=" font-bold">
                                                                                        {
                                                                                            responses
                                                                                                ?.user
                                                                                                ?.fullname
                                                                                        }
                                                                                        -
                                                                                    </span> */}

                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            responses.created_at
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div
                                                                                    className=" flex flex-col w-full bg-opacity-30 p-2 justify-end  break-all rounded-l-md rounded-tr-md shadow-sm leading-4 gap-1"
                                                                                    key={
                                                                                        responses.id
                                                                                    }
                                                                                >
                                                                                    {/* {responses.answer} */}
                                                                                    <p
                                                                                        className="text-sm text-justify break-words"
                                                                                        style={{
                                                                                            whiteSpace:
                                                                                                'pre-line',
                                                                                        }}
                                                                                    >
                                                                                        {responses.answer &&
                                                                                            makeUrlsClickable(
                                                                                                responses.answer
                                                                                            ).map(
                                                                                                (
                                                                                                    part,
                                                                                                    index
                                                                                                ) => (
                                                                                                    <React.Fragment
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            part
                                                                                                        }
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            )}
                                                                                    </p>
                                                                                    {responses.attachments && (
                                                                                        <>
                                                                                            {responses.attachments.filter(
                                                                                                (
                                                                                                    attach
                                                                                                ) =>
                                                                                                    attach.type ===
                                                                                                    'image'
                                                                                            )
                                                                                                .length >
                                                                                                0 && (
                                                                                                <div className="grid grid-cols-3 gap-2 text-[.7em]">
                                                                                                    {responses.attachments
                                                                                                        .filter(
                                                                                                            (
                                                                                                                attach
                                                                                                            ) =>
                                                                                                                attach.type ===
                                                                                                                'image'
                                                                                                        )
                                                                                                        .map(
                                                                                                            (
                                                                                                                attach
                                                                                                            ) => (
                                                                                                                <div
                                                                                                                    key={
                                                                                                                        attach.id
                                                                                                                    }
                                                                                                                    className="overflow-hidden"
                                                                                                                >
                                                                                                                    <img
                                                                                                                        alt={
                                                                                                                            attach.filename
                                                                                                                        }
                                                                                                                        src={
                                                                                                                            attach.filename
                                                                                                                        }
                                                                                                                        className="object-cover w-full h-32"
                                                                                                                    />
                                                                                                                    <Link
                                                                                                                        to={
                                                                                                                            attach.filename
                                                                                                                        }
                                                                                                                        target="_blank"
                                                                                                                        className="flex mt-1"
                                                                                                                    >
                                                                                                                        <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                            Click
                                                                                                                            to
                                                                                                                            view
                                                                                                                        </p>
                                                                                                                    </Link>
                                                                                                                </div>
                                                                                                            )
                                                                                                        )}
                                                                                                </div>
                                                                                            )}

                                                                                            {responses.attachments.filter(
                                                                                                (
                                                                                                    attach
                                                                                                ) =>
                                                                                                    attach.type !==
                                                                                                    'image'
                                                                                            )
                                                                                                .length >
                                                                                                0 && (
                                                                                                <div>
                                                                                                    {responses.attachments
                                                                                                        .filter(
                                                                                                            (
                                                                                                                attach
                                                                                                            ) =>
                                                                                                                attach.type !==
                                                                                                                'image'
                                                                                                        )
                                                                                                        .map(
                                                                                                            (
                                                                                                                attach
                                                                                                            ) => (
                                                                                                                <div
                                                                                                                    className="h-28 w-full py-5 flex break-all text-xs"
                                                                                                                    key={
                                                                                                                        attach.id
                                                                                                                    }
                                                                                                                >
                                                                                                                    <img
                                                                                                                        alt="PDF Icon"
                                                                                                                        className="h-full w-fit"
                                                                                                                        src={
                                                                                                                            PDFIcon
                                                                                                                        }
                                                                                                                    />
                                                                                                                    <div className="w-full">
                                                                                                                        <p className="font-bold text-darkgrey">
                                                                                                                            Filename:
                                                                                                                        </p>
                                                                                                                        <Link
                                                                                                                            className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                                                            to={
                                                                                                                                attach.filename
                                                                                                                            }
                                                                                                                            target="__blank"
                                                                                                                        >
                                                                                                                            {
                                                                                                                                attach.filename
                                                                                                                            }
                                                                                                                        </Link>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        )}
                                                                                                </div>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                            </>
                                        )
                                    )}
                            </>
                        )}
                    </div>
                    <div className=" items-center mb-7 flex flex-col gap-2 p-2 w-[440px] sm:w-full md:w-full ">
                        <div className="w-full text-white rounded-md h-fit bg-main flex items-center justify-between p-2">
                            <p className=" font-semibold text-lg italic">
                                {' '}
                                Most Liked and Disliked Responses
                            </p>
                            <span
                                onClick={() => setDisplayReact(!displayReact)}
                                className="font-normal mb-1 cursor-pointer hover:scale-110"
                            >
                                <FaWindowMinimize style={{ color: '#fff' }} />
                            </span>
                        </div>
                        {!displayReact && (
                            <p className="text-[.6rem] w-full text-center italic">
                                Most Liked and Disliked Responses is Hidden
                            </p>
                        )}
                        {displayReact && (
                            <div className="w-full h-5/6  flex gap-2 px-4 pb-5 flex-col">
                                <div className="w-full h-full rounded-md border border-opacity-50 ">
                                    <div className="w-full h-fit p-2 flex text-center font-bold items-center justify-center text-main border-b border-opacity-50">
                                        {' '}
                                        <span className="-scale-x-100 ">
                                            <BiSolidLike />{' '}
                                        </span>
                                        Most Likes
                                    </div>
                                    <div className="w-full h-fit overflow-y-auto p-5 flex flex-col gap-4">
                                        {dataReact?.most_liked &&
                                            dataReact?.most_liked.map((like) => (
                                                <div
                                                    className="flex flex-col w-full items-end justify-end"
                                                    key={like.id}
                                                >
                                                    <span className="text-xs font-bold mb-2">
                                                        {like?.user?.fullname}
                                                    </span>
                                                    <span className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-end text-left bg-[#e1e1e1] bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                                        {/* {like.answer} */}
                                                        <p
                                                            className="text-sm text-justify break-words"
                                                            style={{
                                                                whiteSpace: 'pre-line',
                                                            }}
                                                        >
                                                            {like.answer &&
                                                                makeUrlsClickable(like.answer).map(
                                                                    (part, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {part}
                                                                        </React.Fragment>
                                                                    )
                                                                )}
                                                        </p>
                                                        {like.attachments && (
                                                            <>
                                                                {like.attachments.filter(
                                                                    (attach) =>
                                                                        attach.type === 'image'
                                                                ).length > 0 && (
                                                                    <div className="grid grid-cols-3 gap-2">
                                                                        {like.attachments
                                                                            .filter(
                                                                                (attach) =>
                                                                                    attach.type ===
                                                                                    'image'
                                                                            )
                                                                            .map((attach) => (
                                                                                <div
                                                                                    key={attach.id}
                                                                                    className="overflow-hidden"
                                                                                >
                                                                                    <img
                                                                                        alt={
                                                                                            attach.filename
                                                                                        }
                                                                                        src={
                                                                                            attach.filename
                                                                                        }
                                                                                        className="object-cover w-full h-32"
                                                                                    />
                                                                                    <Link
                                                                                        to={
                                                                                            attach.filename
                                                                                        }
                                                                                        target="_blank"
                                                                                        className="flex mt-1"
                                                                                    >
                                                                                        <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                            Click to
                                                                                            view
                                                                                        </p>
                                                                                    </Link>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}

                                                                {like.attachments.filter(
                                                                    (attach) =>
                                                                        attach.type !== 'image'
                                                                ).length > 0 && (
                                                                    <div>
                                                                        {like.attachments
                                                                            .filter(
                                                                                (attach) =>
                                                                                    attach.type !==
                                                                                    'image'
                                                                            )
                                                                            .map((attach) => (
                                                                                <div
                                                                                    className="h-28 w-full py-5 flex break-all text-xs"
                                                                                    key={attach.id}
                                                                                >
                                                                                    <img
                                                                                        alt="PDF Icon"
                                                                                        className="h-full w-fit"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                    />
                                                                                    <div className="w-full">
                                                                                        <p className="font-bold text-darkgrey">
                                                                                            Filename:
                                                                                        </p>
                                                                                        <Link
                                                                                            className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                            to={
                                                                                                attach.filename
                                                                                            }
                                                                                            target="__blank"
                                                                                        >
                                                                                            {
                                                                                                attach.filename
                                                                                            }
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        <div className="w-full flex gap-4 justify-between">
                                                            {like.type === 0 && (
                                                                <div className="flex gap-2">
                                                                    <div className="flex">
                                                                        <span className="text-main">
                                                                            <BiSolidLike />
                                                                        </span>
                                                                        <span>
                                                                            {like.likes_count}
                                                                        </span>
                                                                    </div>
                                                                    <div className="flex">
                                                                        <span className="text-grey">
                                                                            <BiSolidDislike />
                                                                        </span>
                                                                        <span>
                                                                            {like.dislikes_count}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div>
                                                                <span>{like.created_at}</span>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="w-full h-full rounded-md mb-4 border border-opacity-50 ">
                                    <div className="w-full h-fit p-2 flex text-center font-bold items-center justify-center text-darkgrey border-b border-opacity-50">
                                        {' '}
                                        <span className="-scale-x-100 ">
                                            <BiSolidDislike />{' '}
                                        </span>
                                        Most Dislikes
                                    </div>
                                    <div className="w-full h-fit overflow-y-auto p-5 flex flex-col gap-4">
                                        {dataReact?.most_disliked &&
                                            dataReact?.most_disliked.map((dislike) => (
                                                <div
                                                    className="flex flex-col w-full items-end justify-end"
                                                    key={dislike.id}
                                                >
                                                    <span className="text-xs font-bold mb-2">
                                                        {dislike?.user?.fullname}
                                                    </span>
                                                    <span className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-end text-left bg-[#e1e1e1] bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                                        {/* {dislike.answer} */}
                                                        <p
                                                            className="text-sm text-justify break-words"
                                                            style={{
                                                                whiteSpace: 'pre-line',
                                                            }}
                                                        >
                                                            {dislike.answer &&
                                                                makeUrlsClickable(
                                                                    dislike.answer
                                                                ).map((part, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {part}
                                                                    </React.Fragment>
                                                                ))}
                                                        </p>
                                                        {dislike.attachments && (
                                                            <>
                                                                {dislike.attachments.filter(
                                                                    (attach) =>
                                                                        attach.type === 'image'
                                                                ).length > 0 && (
                                                                    <div className="grid grid-cols-3 gap-2">
                                                                        {dislike.attachments
                                                                            .filter(
                                                                                (attach) =>
                                                                                    attach.type ===
                                                                                    'image'
                                                                            )
                                                                            .map((attach) => (
                                                                                <div
                                                                                    key={attach.id}
                                                                                    className="overflow-hidden"
                                                                                >
                                                                                    <img
                                                                                        alt={
                                                                                            attach.filename
                                                                                        }
                                                                                        src={
                                                                                            attach.filename
                                                                                        }
                                                                                        className="object-cover w-full h-32"
                                                                                    />
                                                                                    <Link
                                                                                        to={
                                                                                            attach.filename
                                                                                        }
                                                                                        target="_blank"
                                                                                        className="flex mt-1"
                                                                                    >
                                                                                        <p className="font-normal mb-1 break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                            Click to
                                                                                            view
                                                                                        </p>
                                                                                    </Link>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}

                                                                {dislike.attachments.filter(
                                                                    (attach) =>
                                                                        attach.type !== 'image'
                                                                ).length > 0 && (
                                                                    <div>
                                                                        {dislike.attachments
                                                                            .filter(
                                                                                (attach) =>
                                                                                    attach.type !==
                                                                                    'image'
                                                                            )
                                                                            .map((attach) => (
                                                                                <div
                                                                                    className="h-28 w-full py-5 flex break-all text-xs"
                                                                                    key={attach.id}
                                                                                >
                                                                                    <img
                                                                                        alt="PDF Icon"
                                                                                        className="h-full w-fit"
                                                                                        src={
                                                                                            PDFIcon
                                                                                        }
                                                                                    />
                                                                                    <div className="w-full">
                                                                                        <p className="font-bold text-darkgrey">
                                                                                            Filename:
                                                                                        </p>
                                                                                        <Link
                                                                                            className="grow leading-4 break-words truncate-multiline cursor-pointer hover:underline hover:text-blue-500"
                                                                                            to={
                                                                                                attach.filename
                                                                                            }
                                                                                            target="__blank"
                                                                                        >
                                                                                            {
                                                                                                attach.filename
                                                                                            }
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        <div className="w-full flex gap-4 justify-between">
                                                            {dislike.type === 0 && (
                                                                <div className="flex gap-2">
                                                                    <div className="flex">
                                                                        <span className="text-main">
                                                                            <BiSolidLike />
                                                                        </span>
                                                                        <span>
                                                                            {dislike.likes_count}
                                                                        </span>
                                                                    </div>
                                                                    <div className="flex">
                                                                        <span className="text-grey">
                                                                            <BiSolidDislike />
                                                                        </span>
                                                                        <span>
                                                                            {dislike.dislikes_count}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div>
                                                                <span>{dislike.created_at}</span>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </PDFExport>
        </div>
    )
}

export default AnalyticsExport
