import { useEffect, useRef } from 'react'
import { MdOutlineFileDownload } from 'react-icons/md'

export const DownloadDocxButton = ({
    event,
    eventKey,
    selectedEvent,
    openPopupIndexDocx,
    setOpenPopupIndexDocx,
    setModalDocx,
    setSelectedDownload,
}) => {
    const ref = useRef(null)

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenPopupIndexDocx(null)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleDocxClick = ({ id, categories }) => {
        setSelectedDownload({
            id,
            categories,
            sort: 'desc',
        })
        setModalDocx(true)
    }
    console.log('s', selectedEvent)
    return (
        <div className="flex gap-2 items-center justify-center">
            <button
                onClick={() =>
                    setOpenPopupIndexDocx(openPopupIndexDocx === eventKey ? null : eventKey)
                }
                className="text-[10px] relative whitespace-nowrap flex items-center gap-2 h-fit cursor-pointer border-main rounded-md border py-2 px-4 my-2"
            >
                DOCX
                <span className="text-[12px]">
                    <MdOutlineFileDownload />
                </span>
                {openPopupIndexDocx === eventKey && (
                    <div
                        ref={ref}
                        className="z-50 mt-[4.5rem] -ml-9 absolute flex flex-col text-left rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main"
                    >
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() =>
                                handleDocxClick({
                                    id: selectedEvent?.event_id,
                                    categories: ['General Discussions'],
                                })
                            }
                        >
                            General Discussions Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() =>
                                handleDocxClick({
                                    id: selectedEvent?.event_id,
                                    categories: ['Chatroom KIQs'],
                                })
                            }
                        >
                            Chatroom Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() =>
                                handleDocxClick({
                                    id: selectedEvent?.event_id,
                                    categories: ['My KIQs'],
                                })
                            }
                        >
                            My KIQs Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() =>
                                handleDocxClick({
                                    id: selectedEvent?.event_id,
                                    categories: ['SnapIntel'],
                                })
                            }
                        >
                            SnapIntel Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() =>
                                handleDocxClick({
                                    id: selectedEvent?.event_id,
                                    categories: [
                                        'General Discussions',
                                        'Chatroom KIQs',
                                        'My KIQs',
                                        'SnapIntel',
                                    ],
                                })
                            }
                        >
                            Event Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                    </div>
                )}
            </button>
        </div>
    )
}
