import { CheckboxFilter } from 'components/CheckboxFilter'
import ExportGlobalSearch from 'components/Exports/ExportGlobalSearch'
import React, { useEffect, useState } from 'react'
import { FaFileDownload } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import logo from 'assets/NexusBrain.png'
import { IoCloseOutline } from 'react-icons/io5'
import { searchValueAtom } from '..'
import { useAtom } from 'jotai'

export const GlobalSearchBar = ({
    handleSearch,
    searchValue,
    clearSearch,
    showSearchResults,
    handleToggle,
}) => {
    const [searchDl, setSearchDl] = useState(false)
    const [, setSearchValue] = useAtom(searchValueAtom)

    useEffect(() => {
        setSearchValue(searchValue)
    }, [searchValue])

    return (
        <div className="w-full h-fit flex flex-col  justify-evenly items-center p-4 gap-2 pt-2">
            {searchDl && (
                <div className="w-[40%] sm:w-full  fixed right-0 top-8 bottom-0 z-30 h-screen overflo overflow-y-auto bg-white animate_move_to_Left">
                    <ExportGlobalSearch word={searchValue} onClose={() => setSearchDl(false)} />
                </div>
            )}

            <div className="w-2/3 gap-2 md:w-11/12 sm:w-11/12">
                <span className="flex flex-col pt-3">
                    <div className="w-full h-fit flex  items-center justify-center">
                        <input
                            onChange={(e) => handleSearch(e.target.value)}
                            type="text"
                            value={searchValue}
                            className="border w-1/2 grow h-8 border-main outline-none rounded-l-lg rounded-y-lg px-4 py-6 flex bg-white bg-opacity-60 "
                            placeholder="Search: Events, General SnapIntel, Questions, Responses, Users..."
                        />
                        {searchValue?.length > 0 && (
                            <>
                                <div
                                    onClick={() => clearSearch()}
                                    className="w-fit  px-4 py-4 flex bg-main text-white font-bold scale-105 bg-opacity-60 rounded-r-lg border-main cursor-pointer"
                                >
                                    <IoCloseOutline />
                                </div>
                                <button
                                    // disabled={userID == null}
                                    onClick={() => setSearchDl(true)}
                                    className=" cursor-pointer  flex text-[#f7f7f7c0] bg-main ml-1 border-main p-2 rounded-md justify-center flex-col items-center disabled:hidden hover:text-xl transition-all duration-300"
                                >
                                    <FaFileDownload />
                                    <p className="text-xs text-white">
                                        <span className="sm:hidden">Download</span> PDF
                                    </p>
                                </button>
                                <span
                                    onClick={handleToggle}
                                    className="cursor-pointer hover:bg-opacity-100 border ml-2 flex border-gray-400 rounded-md py-3 px-4 bg-white shadow-inner text-main bg-opacity-50"
                                >
                                    {showSearchResults ? (
                                        <img
                                            className=" h-6 w-6  aspect-square "
                                            src={logo}
                                            alt="NexusBrain"
                                        />
                                    ) : (
                                        <FcGoogle size={24} />
                                    )}
                                </span>
                            </>
                        )}

                        <span className="ml-2 z-20 ">
                            <CheckboxFilter />
                        </span>
                    </div>
                </span>
            </div>
        </div>
    )
}
