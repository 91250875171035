import { PDFExport } from '@progress/kendo-react-pdf'
import React, { useRef, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import NexusLogo from '../../assets/NexusBrain.png'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import { Divider } from '@mui/material'
import { Link, NavLink } from 'react-router-dom'
import PDFIcon from '../../assets/pdf.png'
import { DownloadProgress } from 'components/DownloadingComponent'
import axios from 'axios'

const ExportNumberofEntries = ({ eventSelected, data, generalDiscussion, onClose }) => {
    const pdfExportComponent = useRef(null)
    const [loading, setLoading] = useState()
    const [summarizing, setSummarizing] = useState(false)
    const exportPDF = () => {
        setLoading(true) // Start loading

        if (pdfExportComponent.current) {
            pdfExportComponent.current.save((pdfBlob) => {
                if (pdfBlob instanceof Blob) {
                    const fileObject = blobToFile(
                        pdfBlob,
                        `Export Spaces and Events - ${data?.event_name}.pdf`
                    )
                    console.log('File Object:', fileObject) // Log the file object
                    sendPDFToBackend(fileObject) // No need for finally here
                } else {
                    console.error('The exported PDF is not a Blob:', pdfBlob)
                    setLoading(false) // Stop loading in case of error
                }
            })
        } else {
            setLoading(false) // Stop loading if pdfExportComponent is not available
        }
    }

    const blobToFile = (blob, filename) => {
        return new File([blob], filename, { type: 'application/pdf' })
    }

    const [uploadMessage, setUploadMessage] = useState('')
    const [aiSummary, setAiSummary] = useState()

    const sendPDFToBackend = (fileObject) => {
        setSummarizing(true) // Start summarizing

        axios
            .post(
                'https://test-v1.nexusbrain-core-ai-api.nbtechbeta.com/api/summarize/summarize_text',
                eventSelected
            )
            .then((response) => {
                console.log('PDF successfully sent to the server:', response)
                setUploadMessage('PDF uploaded successfully!')
                setAiSummary(response.data)
            })
            .catch((error) => {
                console.error('Error uploading the PDF:', error)
                setUploadMessage('Error uploading PDF. Please try again.')
            })
            .finally(() => {
                setSummarizing(false) // Reset summarizing state
            })
    }
    const makeUrlsClickableAi = (text) => {
        // Replace URLs with anchor tags
        const urlRegex =
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
        text = text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>')

        // Replace newlines with <br />
        text = text.replace(/\n/g, '<br />')

        return { __html: text }
    }
    const makeUrlsClickable = (text) => {
        if (!text) {
            return { __html: '' } // Return an empty string or handle the null case accordingly
        }

        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g

        // Replace matched URLs with clickable <a> tags
        const replacedText = text.replace(
            urlRegex,
            (url) =>
                `<a class="hover:text-blue-600 hover:underline break-all font-bold" href="${url}" target="_blank"><br/> <br/> ${url} <br/> <br/></a>`
        )

        return { __html: replacedText }
    }
    console.log('sda', data)

    return (
        <div className="top-0 bottom-0  right-0 w-full h-full bg-white z-50 pt-4 flex flex-col justify-start items-center">
            <div className="w-full flex flex-col items-center justify-between px-5 sticky -top-4 bg-white pt-4 shadow-md">
                {loading || summarizing ? (
                    <DownloadProgress progress={loading} isSummarizing={summarizing} />
                ) : (
                    <div className="w-full flex items-center justify-between gap-4">
                        {/* {uploadMessage && <div className="notification">{uploadMessage}</div>} */}
                        <div className="flex gap-2">
                            <button
                                onClick={exportPDF}
                                className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                            >
                                Download PDF
                            </button>

                            <button
                                onClick={sendPDFToBackend}
                                className="bg-main px-4 py-3 text-white rounded-md text-[.7em]"
                                disabled={summarizing} // Disable button while summarizing
                            >
                                {summarizing ? 'Summarizing...' : 'AI Summarize'}
                            </button>
                        </div>
                        <p
                            className="text-2xl opacity-70 hover:opacity-100 items-center text-main cursor-pointer"
                            onClick={onClose}
                        >
                            <AiOutlineCloseCircle />
                        </p>
                    </div>
                )}
                <p className="text-xs text-main pt-1 opacity-70 font-semibold">Preview</p>
            </div>
            <div className="w-full flex flex-col justify-center items-center pt-5 ">
                <div className="w-full h-full flex flex-col justify-center items-center">
                    <PDFExport
                        fileName={`Export Spaces and Events - ${eventSelected?.event_name}`}
                        ref={pdfExportComponent}
                        paperSize="A4"
                    >
                        <div className="w-full flex flex-col gap-4 px-2 py-4 justify-center">
                            <div className="w-full flex flex-col gap-4 justify-center items-center">
                                <div className="w-5/6 flex items-center gap-2">
                                    <img src={NexusLogo} alt="NexusLogo" className="w-10" />

                                    <p className="text-main font-bold text-lg justify-between flex">
                                        {eventSelected?.event_name}
                                    </p>
                                </div>
                                <p className=" text-xs w-5/6"> {eventSelected?.description}</p>
                            </div>

                            <div className=" w-5/6 self-center flex flex-col justify-center items-center">
                                <div className="text-xs w-full text-center bg-main rounded-md my-2 shadow-sm py-2 text-white font-semibold ">
                                    General Discussion
                                </div>

                                {generalDiscussion &&
                                    generalDiscussion?.responses.map((response, responseIndex) => (
                                        <div
                                            className="cursor-pointer text-xs  border-main px-2 pt-2"
                                            key={responseIndex}
                                        >
                                            <span className="text-xs font-bold   ">
                                                {response?.user?.fullname}
                                            </span>

                                            <div className="text-xs gap-2 flex flex-col break-all w-fit max-w-[90%] justify-start items-start text-left bg-gray-300  bg-opacity-30 py-2 pl-2 pr-4 rounded-bl-md rounded-r-md shadow-sm">
                                                <p
                                                    className="text-xs w-full text-justify break-words"
                                                    style={{ whiteSpace: 'pre-line' }}
                                                >
                                                    <p
                                                        className="text-xs text-justify"
                                                        dangerouslySetInnerHTML={makeUrlsClickable(
                                                            response.answer
                                                        )}
                                                    />
                                                </p>

                                                {response.attachments &&
                                                    response.attachments?.length > 0 && (
                                                        <div className="flex flex-col gap-2">
                                                            {response.attachments.map(
                                                                (attachment) => (
                                                                    <div
                                                                        className="flex breaak-all"
                                                                        key={attachment.id}
                                                                    >
                                                                        {attachment.type ===
                                                                        'image' ? (
                                                                            <img
                                                                                src={
                                                                                    attachment.filename
                                                                                }
                                                                                alt="Attachment"
                                                                                className="h-[150px]"
                                                                            />
                                                                        ) : attachment.type ===
                                                                          'file' ? (
                                                                            <NavLink
                                                                                to={
                                                                                    attachment.filename
                                                                                }
                                                                                target="__blank"
                                                                                className="transition-all duration-300 flex gap-4 h-fit w-full break-all items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                            >
                                                                                <img
                                                                                    className="w-8"
                                                                                    src={PDFIcon}
                                                                                    alt="TEXT_FILE"
                                                                                />
                                                                                <p className=" text-left text-xs break-all truncate-multiline-2">
                                                                                    {
                                                                                        attachment.filename
                                                                                    }
                                                                                </p>
                                                                            </NavLink>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}

                                                <div className="w-full flex gap-4 justify-between">
                                                    {response.type === 0 && (
                                                        <div className="flex gap-2">
                                                            <div className="flex">
                                                                <span className="text-main">
                                                                    <BiSolidLike />
                                                                </span>
                                                                <span>{response.total_likes}</span>
                                                            </div>
                                                            <div className="flex">
                                                                <span className="text-grey">
                                                                    <BiSolidDislike />
                                                                </span>
                                                                <span>
                                                                    {response.total_dislikes}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div>
                                                        <span>{response.created_at}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-full flex flex-col gap-2">
                                                <div className="px-10 flex gap-2">
                                                    {response.responses?.length > 0 && (
                                                        <div className="flex">
                                                            <span className="rotate-90 text-base justify-center items-center ">
                                                                <MdOutlineSubdirectoryArrowLeft />
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                {response.responses?.length > 0 && (
                                                    <React.Fragment>
                                                        <div className="flex flex-col w-full ml-5 px-6 animate_fade_up">
                                                            {response.responses.map((responses) => (
                                                                <div className="text-xs">
                                                                    <span className="text-xs font-bold">
                                                                        {responses?.user?.fullname}
                                                                    </span>
                                                                    <div
                                                                        className="text-[14px] flex flex-col w-fit gap-3 bg-main  bg-opacity-30 py-2 p-4 my-1 rounded-l-md rounded-tr-md shadow-sm"
                                                                        key={responses.id}
                                                                    >
                                                                        <p
                                                                            className="text-xs text-justify"
                                                                            dangerouslySetInnerHTML={makeUrlsClickable(
                                                                                response.answer
                                                                            )}
                                                                        />

                                                                        {responses.attachments &&
                                                                            responses.attachments
                                                                                ?.length > 0 && (
                                                                                <React.Fragment>
                                                                                    {responses.attachments.map(
                                                                                        (
                                                                                            attachment
                                                                                        ) => (
                                                                                            <div
                                                                                                className="flex "
                                                                                                key={
                                                                                                    attachment.id
                                                                                                }
                                                                                            >
                                                                                                {attachment.type ===
                                                                                                'image' ? (
                                                                                                    <img
                                                                                                        src={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        alt="Attachment"
                                                                                                        className="h-[150px] brea-all"
                                                                                                    />
                                                                                                ) : attachment.type ===
                                                                                                  'file' ? (
                                                                                                    <NavLink
                                                                                                        to={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        target="__blank"
                                                                                                        className="transition-all duration-300 flex gap-4 h-fit break-all items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                                    >
                                                                                                        <img
                                                                                                            className="w-8"
                                                                                                            src={
                                                                                                                PDFIcon
                                                                                                            }
                                                                                                            alt="TEXT_FILE"
                                                                                                        />
                                                                                                        <p className=" text-left  text-xs break-all truncate-multiline-2">
                                                                                                            {
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                        </p>
                                                                                                    </NavLink>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )}
                                                                        <div className="w-full flex gap-4 justify-between">
                                                                            {responses.type ===
                                                                                0 && (
                                                                                <div className="flex gap-2">
                                                                                    <div className="flex">
                                                                                        <span className="text-main">
                                                                                            <BiSolidLike />
                                                                                        </span>
                                                                                        <span>
                                                                                            {
                                                                                                responses.total_likes
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="flex">
                                                                                        <span className="text-grey">
                                                                                            <BiSolidDislike />
                                                                                        </span>
                                                                                        <span>
                                                                                            {
                                                                                                responses.total_dislikes
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            <div>
                                                                                <span className="text-xs">
                                                                                    {
                                                                                        responses.created_at
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    ))}

                                {generalDiscussion?.responses.length > 0 && (
                                    <Divider className="font-bold text-xs text-main text-center my-4">
                                        End of General Discussions
                                    </Divider>
                                )}

                                <div className="flex text-xs w-full gap-2">
                                    {data &&
                                        data.map((kiqs) => (
                                            <div className="w-full flex flex-col gap-2 mt-4">
                                                <div className="flex gap-2">
                                                    <p>Kiqs Group Name :</p>
                                                    <p className="text-main ">{kiqs.kiq.name}</p>
                                                </div>
                                                <div className="text-main flex flex-col gap-2 text-[.8em]">
                                                    {kiqs.kiq.questions &&
                                                        kiqs.kiq.questions.map((questionList) => (
                                                            <>
                                                                <div className="w-full bg-main p-2 rounded-md shadow-sm text-darkgrey text-left font-bold">
                                                                    {questionList.question}
                                                                </div>
                                                                <div className=" w-full px-2 flex flex-col gap-1 ">
                                                                    {questionList.responses &&
                                                                        questionList.responses.map(
                                                                            (
                                                                                response,
                                                                                responseIndex
                                                                            ) => {
                                                                                return (
                                                                                    <>
                                                                                        <div
                                                                                            className="w-5/6 border rounded-md leading-3 border-b text-right justify-self-end self-end px-4 py-1"
                                                                                            key={
                                                                                                responseIndex
                                                                                            }
                                                                                        >
                                                                                            <p className=" font-bold text-black ">
                                                                                                {
                                                                                                    response
                                                                                                        ?.user
                                                                                                        ?.fullname
                                                                                                }
                                                                                            </p>

                                                                                            <p className=" whitespace-break-spaces text-justify text-darkgrey">
                                                                                                <p
                                                                                                    dangerouslySetInnerHTML={makeUrlsClickable(
                                                                                                        response.answer
                                                                                                    )}
                                                                                                />

                                                                                                {response.attachments &&
                                                                                                    response
                                                                                                        .attachments
                                                                                                        ?.length >
                                                                                                        0 && (
                                                                                                        <div className="flex flex-col gap-2 w-full  ">
                                                                                                            {response.attachments.map(
                                                                                                                (
                                                                                                                    attachment
                                                                                                                ) => (
                                                                                                                    <div
                                                                                                                        className="flex"
                                                                                                                        key={
                                                                                                                            attachment.id
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {attachment.type ===
                                                                                                                        'image' ? (
                                                                                                                            // <Link
                                                                                                                            //     to={
                                                                                                                            //         attachment.filename
                                                                                                                            //     }
                                                                                                                            //     key={
                                                                                                                            //         attachment.id
                                                                                                                            //     }
                                                                                                                            //     className="font-bold "
                                                                                                                            // >
                                                                                                                            //     Image
                                                                                                                            //     Link
                                                                                                                            //     :{' '}
                                                                                                                            //     <p className="font-normal break-all text-blue-500 flex hover:underline hover:font-semibold">
                                                                                                                            //         <span>
                                                                                                                            //             <FaFileImage />
                                                                                                                            //         </span>
                                                                                                                            //         Click
                                                                                                                            //         to
                                                                                                                            //         view
                                                                                                                            //     </p>
                                                                                                                            // </Link>
                                                                                                                            <div>
                                                                                                                                <img
                                                                                                                                    alt={
                                                                                                                                        attachment.filename
                                                                                                                                    }
                                                                                                                                    src={
                                                                                                                                        attachment.filename
                                                                                                                                    }
                                                                                                                                    className="max-h-60 break-all"
                                                                                                                                />
                                                                                                                                <Link
                                                                                                                                    to={
                                                                                                                                        attachment.filename
                                                                                                                                    }
                                                                                                                                    key={
                                                                                                                                        attachment.id
                                                                                                                                    }
                                                                                                                                    target="_blank"
                                                                                                                                    className="font-bold mt-2 break-all flex"
                                                                                                                                >
                                                                                                                                    Image
                                                                                                                                    Link
                                                                                                                                    :{' '}
                                                                                                                                    <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                                        Click
                                                                                                                                        to
                                                                                                                                        view
                                                                                                                                    </p>
                                                                                                                                </Link>
                                                                                                                            </div>
                                                                                                                        ) : attachment.type ===
                                                                                                                          'file' ? (
                                                                                                                            <Link
                                                                                                                                to={
                                                                                                                                    attachment.filename
                                                                                                                                }
                                                                                                                                target="_blank"
                                                                                                                                className="font-bold break-all mb-4"
                                                                                                                            >
                                                                                                                                PDF
                                                                                                                                Link
                                                                                                                                :{' '}
                                                                                                                                <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold">
                                                                                                                                    <span>
                                                                                                                                        <img
                                                                                                                                            alt="PDF Display"
                                                                                                                                            className="h-8 break-all"
                                                                                                                                            src={
                                                                                                                                                PDFIcon
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </span>
                                                                                                                                    Go
                                                                                                                                    to
                                                                                                                                    link
                                                                                                                                </span>
                                                                                                                            </Link>
                                                                                                                        ) : null}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            )}
                                                                                                        </div>
                                                                                                    )}
                                                                                            </p>
                                                                                            <div className="w-full flex gap-4 justify-end items-center text-[.8em] text-darkgrey">
                                                                                                {response.type ===
                                                                                                    0 && (
                                                                                                    <div className="flex gap-2 ">
                                                                                                        <div className="flex items-center gap-2">
                                                                                                            <span className="text-darkgrey text-opacity-70">
                                                                                                                <BiSolidLike />
                                                                                                            </span>
                                                                                                            <span>
                                                                                                                {
                                                                                                                    response.total_likes
                                                                                                                }
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="flex items-center gap-2">
                                                                                                            <span className="text-grey">
                                                                                                                <BiSolidDislike />
                                                                                                            </span>
                                                                                                            <span>
                                                                                                                {
                                                                                                                    response.total_dislikes
                                                                                                                }
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}
                                                                                                <div>
                                                                                                    {
                                                                                                        response.created_at
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="w-full items-end justify-end flex gap-2 ">
                                                                                            {response
                                                                                                .responses
                                                                                                ?.length >
                                                                                                0 && (
                                                                                                <Divider>
                                                                                                    Replies
                                                                                                </Divider>
                                                                                            )}
                                                                                        </div>
                                                                                        {response &&
                                                                                            response
                                                                                                .responses
                                                                                                ?.length >
                                                                                                0 && (
                                                                                                <div className="text-[.8em] w-full flex ">
                                                                                                    <div className="flex flex-col w-full text-darkgrey justify-end items-end leading-3 my-1 gap-1">
                                                                                                        {response.responses.map(
                                                                                                            (
                                                                                                                responses
                                                                                                            ) => (
                                                                                                                <div className="w-full flex justify-end items-end flex-col">
                                                                                                                    <div
                                                                                                                        className=" flex flex-col w-fit bg-gray-200 px-2 py-1 bg-opacity-30 rounded-sm shadow-sm text-right"
                                                                                                                        key={
                                                                                                                            responses.id
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <p className=" font-bold w-full text-right">
                                                                                                                            {
                                                                                                                                response
                                                                                                                                    ?.user
                                                                                                                                    ?.fullname
                                                                                                                            }
                                                                                                                        </p>
                                                                                                                        <p
                                                                                                                            dangerouslySetInnerHTML={makeUrlsClickable(
                                                                                                                                responses.answer
                                                                                                                            )}
                                                                                                                        />

                                                                                                                        {response.attachments &&
                                                                                                                            response
                                                                                                                                .attachments
                                                                                                                                ?.length >
                                                                                                                                0 && (
                                                                                                                                <React.Fragment>
                                                                                                                                    {response.attachments.map(
                                                                                                                                        (
                                                                                                                                            attachment
                                                                                                                                        ) => (
                                                                                                                                            <div
                                                                                                                                                className="flex "
                                                                                                                                                key={
                                                                                                                                                    attachment.id
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                {attachment.type ===
                                                                                                                                                'image' ? (
                                                                                                                                                    // <Link
                                                                                                                                                    //     to={
                                                                                                                                                    //         attachment.filename
                                                                                                                                                    //     }
                                                                                                                                                    //     key={
                                                                                                                                                    //         attachment.id
                                                                                                                                                    //     }
                                                                                                                                                    //     className="font-bold "
                                                                                                                                                    // >
                                                                                                                                                    //     Image
                                                                                                                                                    //     Link
                                                                                                                                                    //     :{' '}
                                                                                                                                                    //     <p className="font-normal break-all text-blue-500 flex hover:underline hover:font-semibold">
                                                                                                                                                    //         <span>
                                                                                                                                                    //             <FaFileImage />
                                                                                                                                                    //         </span>
                                                                                                                                                    //         Click
                                                                                                                                                    //         to
                                                                                                                                                    //         view
                                                                                                                                                    //     </p>
                                                                                                                                                    // </Link>
                                                                                                                                                    <div>
                                                                                                                                                        <img
                                                                                                                                                            alt={
                                                                                                                                                                attachment.filename
                                                                                                                                                            }
                                                                                                                                                            src={
                                                                                                                                                                attachment.filename
                                                                                                                                                            }
                                                                                                                                                            className="max-h-60 break-all"
                                                                                                                                                        />
                                                                                                                                                        <Link
                                                                                                                                                            to={
                                                                                                                                                                attachment.filename
                                                                                                                                                            }
                                                                                                                                                            key={
                                                                                                                                                                attachment.id
                                                                                                                                                            }
                                                                                                                                                            target="_blank"
                                                                                                                                                            className="font-bold break-all mt-2 flex"
                                                                                                                                                        >
                                                                                                                                                            Image
                                                                                                                                                            Link
                                                                                                                                                            :{' '}
                                                                                                                                                            <p className="font-normal break-all text-blue-500 hover:underline hover:font-semibold">
                                                                                                                                                                Click
                                                                                                                                                                to
                                                                                                                                                                view
                                                                                                                                                            </p>
                                                                                                                                                        </Link>
                                                                                                                                                    </div>
                                                                                                                                                ) : attachment.type ===
                                                                                                                                                  'file' ? (
                                                                                                                                                    <Link
                                                                                                                                                        to={
                                                                                                                                                            attachment.filename
                                                                                                                                                        }
                                                                                                                                                        target="_blank"
                                                                                                                                                        className="font-bold mb-4"
                                                                                                                                                    >
                                                                                                                                                        PDF
                                                                                                                                                        Link
                                                                                                                                                        :{' '}
                                                                                                                                                        <span className="font-normal break-all text-blue-500 flex items-center hover:underline hover:font-semibold">
                                                                                                                                                            <span>
                                                                                                                                                                <img
                                                                                                                                                                    alt="PDF Display"
                                                                                                                                                                    className="h-8"
                                                                                                                                                                    src={
                                                                                                                                                                        PDFIcon
                                                                                                                                                                    }
                                                                                                                                                                />
                                                                                                                                                            </span>
                                                                                                                                                            Go
                                                                                                                                                            to
                                                                                                                                                            link
                                                                                                                                                        </span>
                                                                                                                                                    </Link>
                                                                                                                                                ) : null}
                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                    )}
                                                                                                                                </React.Fragment>
                                                                                                                            )}
                                                                                                                        <div className="w-full flex gap-4 justify-between ">
                                                                                                                            {response.type ===
                                                                                                                                0 && (
                                                                                                                                <div className="flex gap-2">
                                                                                                                                    <div className="flex items-center">
                                                                                                                                        <span className="text-darkgrey">
                                                                                                                                            <BiSolidLike />
                                                                                                                                        </span>
                                                                                                                                        <span>
                                                                                                                                            {
                                                                                                                                                response.total_likes
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div className="flex items-center">
                                                                                                                                        <span className="text-grey">
                                                                                                                                            <BiSolidDislike />
                                                                                                                                        </span>
                                                                                                                                        <span>
                                                                                                                                            {
                                                                                                                                                response.total_dislikes
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                            <div>
                                                                                                                                <span>
                                                                                                                                    {
                                                                                                                                        response.created_at
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                    </>
                                                                                )
                                                                            }
                                                                        )}
                                                                </div>
                                                            </>
                                                        ))}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </PDFExport>
                </div>
            </div>
            <div className="w-full border-t border-main border-opacity-40 p-2 mb-8 pt-5">
                {/* <p className="text-main font-bold text-md w-full  justify-between flex"> */}
                {aiSummary && aiSummary.status === 'success' ? (
                    <>
                        <p className="text-main font-bold text-lg w-full  justify-between flex">
                            AI Summary of {aiSummary.event_name}
                        </p>
                        <div
                            className="text-sm text-justify px-2 pt-2"
                            dangerouslySetInnerHTML={makeUrlsClickableAi(aiSummary.summary)}
                        />
                    </>
                ) : (
                    <p>No summary available.</p>
                )}{' '}
                {/* </p> */}
            </div>
        </div>
    )
}

export default ExportNumberofEntries
