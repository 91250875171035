import React, { useState } from 'react'
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi'
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import PDFIcon from '../../assets/pdf.png'
import ImageView from 'components/ImageView'
import { ConvertLinksToClickable } from 'components/Responses/ClickableResponses'

function DashboardQuestion({ dataContainer }) {
    const [collapse, setCollapse] = useState(true)
    const [selectedImage, setSelectedImage] = useState()
    const [selectedResponse, setSelectedResponse] = useState(null)

    return (
        <div className="w-full flex flex-col ">
            {selectedImage != null && (
                <ImageView image={selectedImage} onClose={() => setSelectedImage(null)} />
            )}
            <div className="w-full bg-main py-2 px-2 flex justify-between items-center rounded-t-md">
                <p className="font-semibold text-white">
                    <ConvertLinksToClickable text={dataContainer?.question} />{' '}
                </p>
            </div>
            <div className="w-full h-full max-h-[350px] overflow-y-auto px-5 flex flex-col gap-3 bg-gray-200 py-5 rounded-b-md">
                <div className="flex w-full items-center gap-2 px-2 ">
                    <div className="bg-main text-center aspect-square rounded-full pt-1 w-8 h-8 text-white">
                        Q
                    </div>
                    <div className="text-xs bg-main bg-opacity-30 p-2 rounded-r-md rounded-tl-md shadow-sm">
                        <ConvertLinksToClickable text={dataContainer?.question} />{' '}
                    </div>
                </div>

                <div className="w-full flex flex-col gap-2">
                    <div className="px-10 flex gap-2">
                        <span className="rotate-90 text-xl">
                            <MdOutlineSubdirectoryArrowLeft />
                        </span>
                        <p
                            className="font-bold text-sm cursor-pointer"
                            onClick={() => setCollapse(!collapse)}
                        >
                            {collapse
                                ? `Hide (${dataContainer?.responses.length}) responses`
                                : `Show (${dataContainer?.responses.length}) responses`}
                        </p>
                    </div>
                    {collapse && (
                        <React.Fragment>
                            {dataContainer?.responses &&
                                dataContainer?.responses.length > 0 &&
                                dataContainer?.responses.map((response) => (
                                    <div
                                        className="flex flex-col w-full items-end justify-end "
                                        key={response.id}
                                    >
                                        <span className="text-xs font-bold mb-2">
                                            {response?.user?.fullname}
                                        </span>
                                        <span className="text-xs gap-2 flex flex-col w-fit max-w-[90%] justify-start items-end text-left bg-white bg-opacity-30 py-2 pl-2 pr-4 rounded-l-md rounded-tr-md shadow-sm">
                                            <p className="break-all text-justify whitespace-break-spaces">
                                                <ConvertLinksToClickable text={response?.answer} />{' '}
                                            </p>

                                            {response.attachments &&
                                                response.attachments.length > 0 && (
                                                    <React.Fragment>
                                                        {response.attachments.map((attachment) => (
                                                            <div
                                                                className="flex items-end justify-end w-full"
                                                                key={attachment.id}
                                                            >
                                                                {attachment.type === 'image' ? (
                                                                    <img
                                                                        className=" transition-all duration-300 w-[200px] cursor-pointer hover:brightness-50"
                                                                        src={attachment.filename}
                                                                        alt="Attachment"
                                                                        onClick={() =>
                                                                            setSelectedImage(
                                                                                attachment.filename
                                                                            )
                                                                        }
                                                                        // className="h-[150px]"
                                                                    />
                                                                ) : attachment.type === 'file' ? (
                                                                    <NavLink
                                                                        to={attachment.filename}
                                                                        target="__blank"
                                                                        className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                    >
                                                                        <img
                                                                            className="w-8"
                                                                            src={PDFIcon}
                                                                            alt="TEXT_FILE"
                                                                        />
                                                                        <p className=" text-left text-xs break-all truncate-multiline-2">
                                                                            {attachment.filename}
                                                                        </p>
                                                                    </NavLink>
                                                                ) : null}
                                                            </div>
                                                        ))}
                                                    </React.Fragment>
                                                )}
                                            <div className="w-full flex gap-4 justify-between">
                                                {response.type === 0 && (
                                                    <div className="flex gap-2">
                                                        <div className="flex">
                                                            <span className="text-main">
                                                                <BiSolidLike />
                                                            </span>
                                                            <span>{response.total_likes}</span>
                                                        </div>
                                                        <div className="flex">
                                                            <span className="text-grey">
                                                                <BiSolidDislike />
                                                            </span>
                                                            <span>{response.total_dislikes}</span>
                                                        </div>
                                                    </div>
                                                )}
                                                <div>
                                                    <span>{response.created_at}</span>
                                                </div>
                                            </div>
                                        </span>

                                        <div className="w-full flex flex-col  justify-end items-end text-right gap-2">
                                            <div className="px-10 flex gap-2 mt-2">
                                                {response.responses?.length > 0 && (
                                                    <div className="flex gap-2">
                                                        <span className="rotate-90 text-base justify-center items-center ">
                                                            <MdOutlineSubdirectoryArrowLeft />
                                                        </span>
                                                        <span
                                                            onClick={() =>
                                                                setSelectedResponse(
                                                                    selectedResponse === response
                                                                        ? null
                                                                        : response
                                                                )
                                                            }
                                                            className="cursor-pointer "
                                                        >
                                                            {selectedResponse === response
                                                                ? ' Hide'
                                                                : ' Show'}{' '}
                                                            Replies
                                                        </span>
                                                    </div>
                                                )}
                                            </div>

                                            {selectedResponse === response &&
                                                response.responses?.length > 0 && (
                                                    <React.Fragment>
                                                        <div className="flex flex-col items-end justify-end w-full ml-5 px-6 animate_fade_up">
                                                            {response.responses.map((responses) => (
                                                                <div>
                                                                    <span className="text-xs  font-bold">
                                                                        {responses?.user?.fullname}
                                                                    </span>
                                                                    <div
                                                                        className="text-[14px] flex flex-col items-end justify-end w-[90%] gap-3  bg-main  bg-opacity-30 py-2 p-4 my-1 rounded-l-md rounded-br-md shadow-sm"
                                                                        key={responses.id}
                                                                    >
                                                                        <p className="break-all text-justify whitespace-break-spaces">
                                                                            <ConvertLinksToClickable
                                                                                text={
                                                                                    responses?.answer
                                                                                }
                                                                            />
                                                                        </p>

                                                                        {responses.attachments &&
                                                                            responses.attachments
                                                                                ?.length > 0 && (
                                                                                <React.Fragment>
                                                                                    {responses.attachments.map(
                                                                                        (
                                                                                            attachment
                                                                                        ) => (
                                                                                            <div
                                                                                                className="flex "
                                                                                                key={
                                                                                                    attachment.id
                                                                                                }
                                                                                            >
                                                                                                {attachment.type ===
                                                                                                'image' ? (
                                                                                                    <img
                                                                                                        src={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        alt="Attachment"
                                                                                                        className="h-[150px]"
                                                                                                        onClick={() =>
                                                                                                            setSelectedImage(
                                                                                                                attachment.filename
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                ) : attachment.type ===
                                                                                                  'file' ? (
                                                                                                    <NavLink
                                                                                                        to={
                                                                                                            attachment.filename
                                                                                                        }
                                                                                                        target="__blank"
                                                                                                        className="transition-all duration-300 flex gap-4 h-fit items-center bg-[#c6c6c6] px-4 py-2 rounded-lg shadow-inner hover:bg-[#9a9a9a]"
                                                                                                    >
                                                                                                        <img
                                                                                                            className="w-8"
                                                                                                            src={
                                                                                                                PDFIcon
                                                                                                            }
                                                                                                            alt="TEXT_FILE"
                                                                                                        />
                                                                                                        <p className=" text-left text-xs break-all truncate-multiline-2">
                                                                                                            {
                                                                                                                attachment.filename
                                                                                                            }
                                                                                                        </p>
                                                                                                    </NavLink>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )}
                                                                        <div className="w-full flex gap-4 justify-between">
                                                                            {responses.type ===
                                                                                0 && (
                                                                                <div className="flex gap-2">
                                                                                    <div className="flex">
                                                                                        <span className="text-main">
                                                                                            <BiSolidLike />
                                                                                        </span>
                                                                                        <span>
                                                                                            {
                                                                                                responses.total_likes
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="flex">
                                                                                        <span className="text-grey">
                                                                                            <BiSolidDislike />
                                                                                        </span>
                                                                                        <span>
                                                                                            {
                                                                                                responses.total_dislikes
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            <div>
                                                                                <span>
                                                                                    {
                                                                                        responses.created_at
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                        </div>
                                    </div>
                                ))}
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DashboardQuestion
