import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { BiChevronDown } from 'react-icons/bi'
import Default from '../../../assets/Default_White.png'
import LoadingImage from '../../../assets/NexusBrain.png'
import { LoadingComponent } from 'components/LoadingComponent'
import { useAtomValue } from 'jotai'
import { searchValueAtom } from 'pages/MainDashboard'
import { ConvertLinksToClickable } from 'components/Responses/ClickableResponses'

export const Events = ({ searchEvent, setTabs, setDataCon, isLoading, searchTerm }) => {
    const [display, setDisplay] = useState(false)
    const [eventGetter, setEventGetter] = useState(null)
    const [viewChange, setViewChange] = useState(true)
    const searchValue = useAtomValue(searchValueAtom)

    const handleClick = (row) => {
        setDataCon(row)
        setEventGetter(row)
        setTabs(0)
    }

    useEffect(() => {
        const local = localStorage.getItem('selectedData')
        if (local) {
            setEventGetter(JSON.parse(local))
        } else {
            console.log('Item not found in localStorage')
        }
    }, [])

    useEffect(() => {
        if (searchEvent === undefined) {
            console.log('Waiting for searchEvent to be populated...')
        } else if (!searchEvent || searchEvent.length === 0) {
            console.log('searchEvent is empty or null')
        }
    }, [searchEvent])

    const columns = [
        {
            name: 'Event',
            selector: (row) => row?.event_name,
            cell: (row) => (
                <>
                    {viewChange ? (
                        <div
                            onClick={() => handleClick(row)}
                            className="w-full h-fit flex justify-start items-start gap-x-2 min-h-[100px]"
                        >
                            <div className="h-full w-1/4 p-2">
                                {row.responses && row.responses.length > 0 ? (
                                    <div>
                                        {row.responses[0].attachments &&
                                        row.responses[0].attachments.length > 0 &&
                                        row.responses[0].attachments[0].type === 'image' ? (
                                            <img
                                                src={row.responses[0].attachments[0].filename}
                                                alt={row.responses[0].attachments[0].filename}
                                                className="cursor-pointer transition-all duration-300 max-h-[200px] max-w-[300px] rounded-md hover:opacity-70 object-contain w-full hover:object-scale-down"
                                            />
                                        ) : (
                                            <div>
                                                <img
                                                    src={Default}
                                                    className="rounded-md"
                                                    alt="Data"
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <img src={Default} className="rounded-md" alt="Data" />
                                    </div>
                                )}
                            </div>

                            <div className="w-3/4 p-2">
                                <ConvertLinksToClickable
                                    text={row?.event_name}
                                    className={`cursor-pointer font-bold text-main text-lg ${
                                        eventGetter?.event_name === row?.event_name && 'underline'
                                    }`}
                                />
                                <p>
                                    <div>
                                        <b>Date: </b> {row.created_at}
                                    </div>
                                </p>
                                <div className="truncate-multiline">
                                    <ConvertLinksToClickable
                                        text={row?.description}
                                        className="mt-3 truncate-multiline"
                                    />{' '}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <ConvertLinksToClickable
                            text={row?.event_name}
                            className="cursor-pointer font-bold"
                        />
                    )}
                </>
            ),
        },
    ]

    if (isLoading) {
        return <LoadingComponent title="Events" />
    }

    return (
        <div className="w-full">
            <button
                onClick={() => {
                    setDisplay(!display)
                    if (searchEvent && searchEvent.length > 0) {
                        const firstEvent = searchEvent[0]
                        setDataCon(firstEvent)
                        setEventGetter(firstEvent)
                        setTabs(0)
                    }
                }}
                disabled={!searchEvent || searchEvent.length === 0}
                className="transition-all duration-200 cursor-pointer flex justify-between p-4 w-full hover:bg-[#7c7c] rounded-md shadow-md animate_fade_up relative bg-[#94ed94cc] text-white z-10"
            >
                <div className="flex items-center">
                    <span className="text-xl">
                        <BiChevronDown />
                    </span>
                    <p>Events</p>
                </div>
                {searchEvent ? (
                    <p className="border-white border px-2 rounded-full font-bold shadow-md">
                        {searchEvent?.length ?? 0}
                    </p>
                ) : (
                    <img src={LoadingImage} className="w-[25px] animate_slow_spin" alt="Loading" />
                )}
            </button>

            {display && (
                <div className="Card-Styling transition-all duration-200 cursor-pointer w-full bg-white shadow-md pt-4 pb-2 px-4 -mt-5 rounded-md animate_fade_up">
                    <DataTable
                        progressComponent={<LoadingComponent title="Events" />}
                        progressPending={!searchEvent}
                        data={searchEvent}
                        columns={columns}
                        noHeader={true}
                        fixedHeader
                        fixedHeaderScrollHeight="300px"
                        pagination
                    />
                </div>
            )}
        </div>
    )
}
