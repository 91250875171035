import React, { useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import LoadingImage from '../../../assets/NexusBrain.png'
import DataTable from 'react-data-table-component'
import ResponsesChatroom from 'components/ChatSelection/ResponsesChatroom'
import { LoadingComponent } from 'components/LoadingComponent'
import SnapIntelUpdate from 'components/ChatSelection/RecentSnapIntelUpdate'
import GenSnapIntelUpdate from 'components/ChatSelection/RecentGenSnapUpdate'
import { ConvertLinksToClickable } from 'components/Responses/ClickableResponses'

export const Responses = ({ searchResponses, setTabs, setResponsesContainer }) => {
    const [display, setDisplay] = useState(false)
    const [responseHolder, setResponseHolder] = useState()

    const columns = [
        {
            name: 'Respondents',
            selector: (row) => row.user?.fullname,
            cell: (row) => (
                <div
                    className={`${
                        responseHolder === row &&
                        'font-bold  border rounded-md px-10 bg-opacity-80 hover:bg-opacity-100 bg-main text-white shadow-md underline'
                    } w-full flex flex-col gap-2 py-2 `}
                    onClick={() => handleRowClicked(row)}
                >
                    <p className="font-bold">{row.user?.fullname}</p>

                    <p className="break-all whitespace-break-spaces">
                        <ConvertLinksToClickable text={row?.answer} />{' '}
                    </p>
                    <div className="flex justify-between w-full">
                        <p className="font-bold">{row.created_at}</p>
                        <p className="font-bold">
                            {row.type === 0
                                ? 'Chatroom KIQs'
                                : row.type === 1
                                ? 'My KIQS'
                                : 'SnapIntel'}
                        </p>
                    </div>
                </div>
            ),
        },
    ]

    const handleRowClicked = (row) => {
        setResponsesContainer(row)
        setResponseHolder(row)
        setTabs(5)
    }
    return (
        <div className="w-full">
            <button
                onClick={() => {
                    setDisplay(!display)
                    setResponsesContainer(searchResponses[0])
                    setResponseHolder(searchResponses[0])
                    setTabs(5)
                }}
                disabled={searchResponses?.length == 0 && true}
                className="transition-all duration-200 cursor-pointer text-white flex justify-between p-4 w-full bg-[#4a8a4a] rounded-md shadow-md animate_fade_up relative hover:bg-opacity-100 hover:text-white z-10"
            >
                <div className="flex items-center">
                    <span className="text-xl">
                        <BiChevronDown />
                    </span>
                    <p>Responses</p>
                </div>
                {searchResponses ? (
                    <p className="border-white border px-2 rounded-full font-bold shadow-md">
                        <React.Fragment>{searchResponses?.length ?? 0} </React.Fragment>
                    </p>
                ) : (
                    <img
                        src={LoadingImage}
                        alt="Loading..."
                        className="w-[25px] animate_slow_spin brightness-200"
                    />
                )}
            </button>
            {display && (
                <React.Fragment>
                    <div className=" transition-all duration-200 cursor-pointer w-full bg-white shadow-md pt-4 pb-2 px-4 -mt-5 rounded-md animate_fade_up">
                        <DataTable
                            progressComponent={<LoadingComponent title={'Responses'} />}
                            progressPending={!searchResponses}
                            data={searchResponses}
                            columns={columns}
                            fixedHeader
                            fixedHeaderScrollHeight="300px"
                            pagination
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export const ResponsesDisplay = ({ isLoading, dataContainer }) => {
    return (
        <div className="h-full bg-white  rounded-b-lg grow flex flex-col min-h-[300px] p-10 gap-4 ">
            <p className="text-4xl text-main font-bold">{dataContainer?.event?.event_name}</p>
            <div className="w-full">
                <p className="text-xl text-main font-bold">Description</p>
                <p>{dataContainer?.event?.description}</p>
            </div>

            <div className="w-full custom-scroll">
                <div className="w-full min-h-[200px] h-fit flex flex-col ">
                    <div className="w-full h-full flex grow">
                        <ResponsesChatroom dataContainer={dataContainer} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export const UpdateDisplay = ({ isLoading, dataContainer }) => {
    return (
        <div className="h-full bg-white  rounded-b-lg grow flex flex-col min-h-[300px] p-10 gap-4 ">
            <p className="text-4xl text-main font-bold">{dataContainer?.event_name}</p>
            <div className="w-full">
                <p className="text-xl text-main font-bold">Description</p>
                <p>{dataContainer?.description}</p>
            </div>

            <div className="w-full custom-scroll">
                <div className="w-full min-h-[460px] h-fit flex flex-col ">
                    <div className="w-full h-full flex grow">
                        <SnapIntelUpdate dataContainer={dataContainer} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export const UpdateDisplayGenSnap = ({ isLoading, dataContainer }) => {
    return (
        <div className="h-full bg-white  rounded-b-lg grow flex flex-col min-h-[300px] p-10 gap-4 ">
            <p className="text-4xl text-main font-bold">{dataContainer?.firstname}</p>
            <div className="w-full">
                <p className="text-xl text-main font-bold">Description</p>
                <p>{dataContainer?.domain_name}</p>
            </div>

            <div className="w-full custom-scroll">
                <div className="w-full min-h-[450px] h-fit flex flex-col ">
                    <div className="w-full h-full flex grow">
                        <GenSnapIntelUpdate dataContainer={dataContainer} />
                    </div>
                </div>
            </div>
        </div>
    )
}
