import React from 'react'
import { MdOutlineFileDownload } from 'react-icons/md'

const DocxDownload = ({openModalDocx, dataEvent}) => {
  return (
    <div className=" z-50 mt-[7.5rem] -ml-9 absolute flex flex-col text-left  rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
    <button
        className="text-xs flex items-center justify-between gap-2 cursor-pointer"
        onClick={() => {
            openModalDocx({
                id: dataEvent?.id,
                categories: ['General Discussion'],
                sort: 'desc',
            })
        }}
    >
        General Discussions Docx{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>
    <button
        className="text-xs flex items-center justify-between gap-2  cursor-pointer"
        onClick={() => {
            openModalDocx({
                id: dataEvent?.id,
                categories: ['Chatroom KIQs'],
                sort: 'desc',
            })
        }}
    >
        Chatroom Docx{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>
    <button
        className="text-xs flex items-center justify-between gap-2 cursor-pointer"
        onClick={() => {
            openModalDocx({
                id: dataEvent?.id,
                categories: ['My KIQs'],
                sort: 'desc',
            })
        }}
    >
        My KIQs Docx{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>
    <button
        className="text-xs flex items-center justify-between gap-2 cursor-pointer"
        onClick={() => {
            openModalDocx({
                id: dataEvent?.id,
                categories: ['SnapIntel'],
                sort: 'desc',
            })
        }}
    >
        SnapIntel Docx{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>
    <button
        className="text-xs flex items-center justify-between gap-2 cursor-pointer"
        onClick={() =>
          
            openModalDocx({
                id: dataEvent?.id,
                categories:  [
                    'General Discussions',
                    'Chatroom KIQs',
                    'My KIQs',
                    'SnapIntel',
                ],
                sort: 'desc',
            })
        }
    >
        Event Docx{' '}
        <span className="text-lg">
            <MdOutlineFileDownload />
        </span>
    </button>
</div>  )
}

export default DocxDownload