import { useAtomValue } from 'jotai'
import React from 'react'
import { searchValueAtom } from 'pages/MainDashboard'
import Highlighter from 'react-highlight-words'

export const ConvertLinksToClickable = ({ text, className }) => {
    const searchValue = useAtomValue(searchValueAtom)

    if (!text) {
        return null
    }

    const textContent = String(text)

    const searchWords = searchValue ? searchValue.split(' ') : []

    const urlRegex = /https?:\/\/[^\s]+/g
    const parts = textContent.split(urlRegex)
    const links = textContent.match(urlRegex)

    if (!links) {
        return (
            <Highlighter
                searchWords={searchWords}
                autoEscape={true}
                textToHighlight={textContent}
                highlightClassName="bg-yellow-200"
                className={className}
            />
        )
    }

    return parts.map((part, index) => {
        let result = [
            <Highlighter
                key={`text-${index}`}
                searchWords={searchWords}
                autoEscape={true}
                textToHighlight={part}
                highlightClassName="bg-yellow-200"
                className={className}
            />,
        ]

        if (links[index]) {
            result.push(
                <a
                    key={`link-${index}`}
                    href={links[index]}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'blue', textDecoration: 'underline' }}
                >
                    <Highlighter
                        searchWords={searchWords}
                        autoEscape={true}
                        textToHighlight={links[index]}
                        highlightClassName="bg-yellow-200"
                        className={className}
                    />
                </a>
            )
        }
        return result
    })
}
